import {
  CHECK_RAZORPAY_PAYMENT_REQUEST,
  CHECK_RAZORPAY_PAYMENT_SUCCESS,
  CHECK_RAZORPAY_PAYMENT_FAILURE
} from '../constants';

const initialState = {
  checkRazorpayPaymentStatus: false,
  razorPayPaymentResponse: { isPaymentSignatureValid: false },
  razorPayPaymentStatus: false
};

interface stateType {
  checkRazorpayPaymentStatus: boolean;
  razorPayPaymentResponse: Object;
  razorPayPaymentStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const razorPayPaymentReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CHECK_RAZORPAY_PAYMENT_REQUEST:
    return {
      ...state,
      checkRazorpayPaymentStatus: true,
      razorPayPaymentResponse: {},
      razorPayPaymentStatus: false
    };

  case CHECK_RAZORPAY_PAYMENT_SUCCESS:
    return {
      ...state,
      success: true,
      checkRazorpayPaymentStatus: false,
      razorPayPaymentResponse: action.payload.data,
      razorPayPaymentStatus: false
    };
  case CHECK_RAZORPAY_PAYMENT_FAILURE:
    return {
      checkRazorpayPaymentStatus: false,
      razorPayPaymentResponse: {},
      razorPayPaymentStatus: false,
      error: action?.payload
    };

  default:
    return {};
  }
};
