import { GET_CURRENCY_CONVERSION_REQUEST,GET_CURRENCY_CONVERSION_SUCCESS,GET_CURRENCY_CONVERSION_FAILURE } from '../constants';

const initialState = {
  currencyDataRequest: false,
  currencyRateData: {},
  currencyRateDataStatus: false
};

interface stateType {
  currencyDataRequest: boolean;
  currencyRateData: Object;
  currencyRateDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const currencyConversionReducer = (state = initialState, action: actionType): stateType => {    
  switch (action.type) {
  case GET_CURRENCY_CONVERSION_REQUEST:
    return {
      ...state,
      currencyDataRequest: true,
      currencyRateData: {},
      currencyRateDataStatus: false
    };
  case GET_CURRENCY_CONVERSION_SUCCESS:
    return {
      ...state,
      currencyDataRequest: false,
      currencyRateData: action.payload.data,
      currencyRateDataStatus: true,
    };
  case GET_CURRENCY_CONVERSION_FAILURE:
    return {
      ...state,
      currencyDataRequest: false,
      currencyRateData: {},
      currencyRateDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
