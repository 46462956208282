import {
  FETCH_RAISE_ISSUE_DATA_REQUEST,
  FETCH_RAISE_ISSUE_DATA_SUCCESS,
  FETCH_RAISE_ISSUE_DATA_FAILURE,
  FETCH_RAISE_ISSUE_DATA_RESET
} from '../constants';

const initialState = {
  raiseIssueDataRequest: false,
  raiseIssueData: {},
  raiseIssueDataStatus: false
};

interface stateType {
  raiseIssueDataRequest: boolean;
  raiseIssueData: any;
  raiseIssueDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const fetchRaiseIssueDetailReducer = (
  state = initialState,
  action: actionType
): stateType => {
  switch (action.type) {
  case FETCH_RAISE_ISSUE_DATA_REQUEST:
    return {
      ...state,
      raiseIssueDataRequest: true,
      raiseIssueData: {},
      raiseIssueDataStatus: false
    };
  case FETCH_RAISE_ISSUE_DATA_SUCCESS:
    return {
      ...state,
      raiseIssueDataRequest: false,
      raiseIssueData: action.payload.data,
      raiseIssueDataStatus: true
    };
  case FETCH_RAISE_ISSUE_DATA_FAILURE:
    return {
      ...state,
      raiseIssueDataRequest: false,
      raiseIssueData: {},
      raiseIssueDataStatus: false
    };
  case FETCH_RAISE_ISSUE_DATA_RESET:
    return initialState;

  default:
    return state;
  }
};
