import { QUOTE_TAB } from 'src/constants';
import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_DATA_RESET,
} from '../constants';

const emptyRecordsObject = {
  [QUOTE_TAB.ALL_OPEN]: [],
  [QUOTE_TAB.RECEIVED]: [],
  [QUOTE_TAB.NOT_RECEIVED]: [],
  [QUOTE_TAB.CLOSED]: [],
};

const emptyQuotesData = {
  [QUOTE_TAB.ALL_OPEN]: {},
  [QUOTE_TAB.RECEIVED]: {},
  [QUOTE_TAB.NOT_RECEIVED]: {},
  [QUOTE_TAB.CLOSED]: {},
};

const initialState = {
  quoteDataRequest: false,
  quoteData: emptyQuotesData,
  quoteDataStatus: false,
  records: emptyRecordsObject,
};

interface stateType {
  quoteDataRequest: boolean;
  quoteData: { [key: string]: any };
  quoteDataStatus: boolean;
  records: { [key: string]: any };
}

interface actionType {
  type: string;
  payload: {
    quoteTab: string;
    data: {
      records: Array<Object>;
    };
  };
}

export const viewQuotesReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_DATA_REQUEST:
    return {
      ...state,
      quoteDataRequest: true,
      quoteDataStatus: false,
    };

  case FETCH_DATA_SUCCESS: {
    const { records } = state;
    const oldData = records[action.payload.quoteTab as keyof typeof records];
    return {
      ...state,
      quoteDataRequest: false,
      quoteData: {
        ...state.quoteData,
        [action.payload.quoteTab]: action.payload.data,
      },
      quoteDataStatus: true,
      records: {
        ...state.records,
        [action.payload.quoteTab]: [...oldData, ...action.payload.data.records],
      },
    };
  }
  case FETCH_DATA_FAILURE:
    return {
      ...state,
      quoteDataRequest: false,
      quoteData: emptyQuotesData,
      quoteDataStatus: false,
      records: emptyRecordsObject,
    };
  case FETCH_DATA_RESET:
    return {
      ...state,
      quoteDataRequest: false,
      quoteData: emptyQuotesData,
      quoteDataStatus: false,
      records: emptyRecordsObject,
    };
  default:
    return {
      ...state,
    };
  }
};
