import { FunctionComponent, ReactElement, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routesArray } from './_base';
import LoaderPage from 'src/components/LoaderPage';
import ProtectedRoute from './ProtectedRoute';
import { Navigate } from './constants';
import { reactPagePropsType } from 'src/interfaces/reactPageProps';
import { isWebView } from 'src/utils/generic';

const Routes = (): ReactElement => {
  type propTypes = {
    path: string;
    Component: FunctionComponent<reactPagePropsType>;
    exact?: boolean;
    isProtected?: boolean;
  };

  return (
    <>
      <Suspense fallback={isWebView ? <></> : <LoaderPage />}>
        <Switch>
          {routesArray?.map(
            ({ path, Component, exact = true, isProtected }: propTypes, index: number) =>
              isProtected ? (
                <ProtectedRoute path={path} key={index} component={Component} exact={exact} />
              ) : (
                <Route path={path} key={index} component={Component} exact={exact} />
              )
          )}

          <Route path="/">
            <Redirect to={Navigate.login} />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;
