import { CUSTOMER_NOTIFY_REQUEST, CUSTOMER_NOTIFY_SUCCESS, CUSTOMER_NOTIFY_FAILURE } from '../constants';

const initialState = {
  customerNotifyDataRequest:false,
  customerNotifyData:{},
  customerNotifyDataStatus:false,
};

interface stateType {
  customerNotifyDataRequest: boolean;
  customerNotifyData: Object;
  customerNotifyDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const customerNotifyReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CUSTOMER_NOTIFY_REQUEST:
    return {
      ...state,
      customerNotifyDataRequest:false,
      customerNotifyData:{},
      customerNotifyDataStatus:false,
      provisionalOrderError:false,
    };

  case CUSTOMER_NOTIFY_SUCCESS:
    return {
      ...state,
      success: true,
      customerNotifyDataRequest:false,
      customerNotifyData:action.payload.data,
      customerNotifyDataStatus:false,
      provisionalOrderError:false,
    };
  case CUSTOMER_NOTIFY_FAILURE:
    return {
      customerNotifyDataRequest:false,
      customerNotifyData:{},
      customerNotifyDataStatus:false,
      error: action?.payload
    };
    
  default:
    return {};
  }
};
