import {
  VERIFY_REFERENCE_CODE_FAILURE,
  VERIFY_REFERENCE_CODE_REQUEST,
  VERIFY_REFERENCE_CODE_SUCCCESS,
} from '../constants';

const initialState = {
  error: false,
  approvalCodeData: {},
};

interface stateType {
  success?: boolean;
  error?: boolean;
  approvalCodeData?: object;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const verifyReferenceCodeReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case VERIFY_REFERENCE_CODE_REQUEST:
    return {
      error: false,
      success: false,
      approvalCodeData: {},
    };

  case VERIFY_REFERENCE_CODE_SUCCCESS:
    return {
      success: true,
      approvalCodeData: action.payload.data,
      error: false,
    };
  case VERIFY_REFERENCE_CODE_FAILURE:
    return {
      error: true,
      approvalCodeData: action.payload,
      success: false,
    };

  default:
    return {};
  }
};
