import {
  GET_LOGISTIC_COST_REQUEST,
  GET_LOGISTIC_COST_SUCCESS,
  GET_LOGISTIC_COST_FAILURE
} from '../constants';

const initialState = {
  logisticCostDataRequest: false,
  fetchLogisticCostData: {},
  logisticCostDataStatus: false
};

interface stateType {
  logisticCostDataRequest: boolean;
  fetchLogisticCostData: Object;
  logisticCostDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const autoLogisticCalculationReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case GET_LOGISTIC_COST_REQUEST:
    return {
      ...state,
      logisticCostDataRequest: true,
      fetchLogisticCostData: {},
      logisticCostDataStatus: false
    };
  case GET_LOGISTIC_COST_SUCCESS:
    return {
      ...state,
      logisticCostDataRequest: false,
      fetchLogisticCostData: action.payload.data,
      logisticCostDataStatus: true
    };
  case GET_LOGISTIC_COST_FAILURE:
    return {
      ...state,
      logisticCostDataRequest: false,
      fetchLogisticCostData: {},
      logisticCostDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
