import { CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE } from '../constants';

const initialState = {
  cancelOrderLoadingStatus: false,
  cancelOrderResponse: { success: false },
  cancelOrderStatus: false
};

interface stateType {
  cancelOrderLoadingStatus: boolean;
  cancelOrderResponse: Object;
  cancelOrderStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const cancelOrderReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CANCEL_ORDER_REQUEST:
    return {
      ...state,
      cancelOrderLoadingStatus: false,
      cancelOrderResponse: {},
      cancelOrderStatus: false
    };

  case CANCEL_ORDER_SUCCESS:
    return {
      ...state,
      success: true,
      cancelOrderLoadingStatus: false,
      cancelOrderResponse: action.payload.data,
      cancelOrderStatus: false
    };
  case CANCEL_ORDER_FAILURE:
    return {
      cancelOrderLoadingStatus: false,
      cancelOrderResponse: {},
      cancelOrderStatus: false,
      error: action?.payload
    };

  default:
    return {};
  }
};
