import { ReactElement } from 'react';
import { ReactComponent as LoaderLogo } from 'src/assets/LoaderLogo.svg';

const LoaderPage = (): ReactElement => {
  return (
    <div className="min-w-primary min-h-screen phone:max-w-[40%] mx-auto bg-primehome bg-opacity-80">
      <div className="flex justify-center py-[50%] animate-pulse ">
        <LoaderLogo />
      </div>
    </div>
  );
};
export default LoaderPage;
