import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: '\'Nunito Sans\', sans-serif',
  },
  colors: {
    brandBlue: {
      50: '#ddf2ff',
      100: '#aed5ff',
      200: '#7dbaff',
      300: '#4b9eff',
      400: '#1a82ff',
      500: '#0067e2',
      600: '#0051b4',
      700: '#003a82',
      800: '#002351',
      900: '#000d21',
    },
    brandRed: {
      500: '#EB5757',
      400: '#EB5757',
    },
    brandBorder: {
      100: '#D4D4D4',
    },
  },
  components: {
    Badge: {
      variants: {
        'data-pill': {
          borderRadius: '16px',
          background: 'rgba(225, 225, 234, 0.2);',
          padding: '4px 8px',
          textTransform: 'capitalize',
          color: '#676773',
          fontSize: '14px',
          fontWeight: 'normal',
          textAlign: 'center',
        },
      },
    },
    Button: {
      variants: {
        outline: {
          color: 'brandBlue.500',
        },
        ghost: {
          color: 'brandBlue.500',
        },
        'solid-rounded': {
          display: 'flex',
          alignItems: 'center',
          background: '#f2f8ff',
          borderRadius: '24px',
          color: 'brandBlue.500',
          height: 'auto',
          padding: '12px 16px',
        },
      },
      sizes: {
        lg: {
          fontSize: '14px',
          h: '40px',
        },
      },
    },
    Divider: {
      sizes: {
        'lg-overflown': {
          height: '2px',
          marginRight: '-24px',
          marginLeft: '-24px',
          borderColor: '#F2F2F2',
          fontSize: '14px',
        },
      },
    },
    Input: {
      parts: ['field'],
      variants: {
        outline: {
          field: {
            borderColor: 'brandBorder.100',
            _focus: {
              borderColor: 'brandBlue.500',
              boxShadow: 'none',
            },
            _invalid: {
              borderColor: 'brandRed.400',
              boxShadow: 'none',
            },
          },
        },
      },
      sizes: {
        lg: {
          field: {
            h: '48px',
            fontSize: '14px',
          },
          addon: {
            fontSize: '14px',
          },
        },
      },
      defaultProps: {
        size: 'lg',
      },
    },
  },
});

export default theme;
