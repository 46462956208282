import { GET_BANKS_SUCCESS,GET_BANKS_FAILURE,GET_BANKS_REQUEST } from '../constants';

const initialState={
  banksDetailsDataRequest:false,
  banksDetail:{},
  banksDetailsDataStatus:false
}

interface stateType{
    banksDetailsDataRequest:boolean;
    banksDetail:any;
    banksDetailsDataStatus:boolean;
}

interface actionType{
    type:string,
    payload:{
        data:any;
    };
}

export const getbanksReducer=(state=initialState,action:actionType): stateType => {
  switch(action.type){
  case GET_BANKS_REQUEST:
    return{
      ...state,
      banksDetailsDataRequest:true,
      banksDetail:{},
      banksDetailsDataStatus:false,
    }
  case GET_BANKS_SUCCESS:
    return{
      ...state,
      banksDetailsDataRequest:false,
      banksDetail:action.payload.data,
      banksDetailsDataStatus:true,
    }
  case GET_BANKS_FAILURE:
    return{
      ...state,
      banksDetailsDataRequest:false,
      banksDetail:{},
      banksDetailsDataStatus:false,
    };
  default:
    return{
      ...state
    };
  }

};