import { IS_NEW_CUSTOMER, HEADER_IGNORE_401, HEADER_IGNORE_403 } from 'src/constants';
import { OTP_VERIFY_URL } from 'src/urls';
import axios from 'axios';
import {
  APP_LINK_VERIFIED,
  CHECK_OTP_FAILURE,
  CHECK_OTP_NEW_CUSTOMER,
  CHECK_OTP_REQUEST,
  CHECK_OTP_RESET,
  CHECK_OTP_SUCCESS,
  FETCH_EDIT_NUMBER,
  FETCH_OTP_FAILURE,
  FETCH_OTP_REQUEST,
  FETCH_OTP_RESET,
  FETCH_OTP_SUCCESS,
  SIGNUP_TOAST,
} from 'src/redux/constants';
import storage from 'redux-persist/lib/storage';
export const sendOtpRequest = (inputData: string, isEmail: boolean) => {
  return function (dispatch: Function): void {
    dispatch(fetchOtpRequest());
    const otpSendUrl = `${process.env.REACT_APP_API_URL}/apifabric/auth/otp/send/`;
    const payload = isEmail ? { email: inputData } : { mobile: inputData };
    axios
      .post(
        otpSendUrl, payload,
        {
          headers: {
            [HEADER_IGNORE_403]: 'true',
            [HEADER_IGNORE_401]: 'true'
          },
        },
      )
      .then((res) => {
        dispatch(fetchOtpSuccess(inputData, res));
      })
      .catch((error) => {
        dispatch(fetchOtpFailure(error.response.data));
      });
  };
};
export const fetchOtpRequest = (): Object => {
  return {
    type: FETCH_OTP_REQUEST,
  };
};
export const fetchOtpSuccess = (inputData: string, res: any): Object => {
  return {
    type: FETCH_OTP_SUCCESS,
    payload: {
      inputData,
      res: res.data.data,
    },
  };
};
export const fetchOtpFailure = (error: { message: string; code: number }): Object => {
  return {
    type: FETCH_OTP_FAILURE,
    payload: { error },
  };
};
export const fetchEditNumber = (inputData: string): Object => {
  return {
    type: FETCH_EDIT_NUMBER,
    payload: {
      inputData: inputData,
    },
  };
};
export const fetchOtpReset = (): Object => {
  return {
    type: FETCH_OTP_RESET,
  };
};
export const sendOTP = (OTPNumber: string, inputData: string, isEmail: boolean): Function => {
  return function (dispatch: Function): void {
    dispatch(checkOtpRequest());
    const otpVerifyUrl = `${process.env.REACT_APP_API_URL}/${OTP_VERIFY_URL}`;
    const payload = isEmail
      ? { otp: OTPNumber, email: inputData, appType: 'Customer' }
      : { otp: OTPNumber, mobile: inputData, appType: 'Customer' };
    axios.post(otpVerifyUrl, payload , {headers: {
      [HEADER_IGNORE_403]: 'true',
      [HEADER_IGNORE_401]: 'true'
    }}).then(
      (response) => {
        const token = response.data.data.accessToken;
        const isNewCustomer = response.data.data.isNewCustomer;
        if (isNewCustomer === IS_NEW_CUSTOMER) {
          storage.setItem('isNewCustomer', response.data.data.isNewCustomer);
          storage.setItem('phoneNumber', response.data.data.phoneNumber);
          storage.setItem('gstValidationToken', response.data.data.gstValidationToken);
          dispatch(checkOtpNewCustomer());
        } else {
          storage.setItem('Token', token);
          storage.setItem('Name', response.data.data.name);
          storage.setItem('BrandId', response.data.data.brandId);
          dispatch(checkOtpSuccess(inputData, token));
        }
      })
      .catch((error) => {
        dispatch(checkOtpFailure(error));
      });
  };
};
export const checkOtpRequest = (): Object => {
  return {
    type: CHECK_OTP_REQUEST,
  };
};
export const checkOtpSuccess = (inputData: string, token: string): object => {
  return {
    type: CHECK_OTP_SUCCESS,
    payload: {
      inputData: inputData,
      token: token,
    },
  };
};
export const checkOtpFailure = (error: string): Object => {
  return {
    type: CHECK_OTP_FAILURE,
    payload: {
      error,
    },
  };
};
export const checkOtpReset = (redirectUrl?: string): Object => {
  return {
    type: CHECK_OTP_RESET,
    payload: {
      redirectUrl,
    },
  };
};
export const appLinkVerified = (token: string): object => {
  return {
    type: APP_LINK_VERIFIED,
    payload: {
      token: token,
    },
  };
};
export const checkOtpNewCustomer = (): Object => {
  return {
    type: CHECK_OTP_NEW_CUSTOMER,
  };
};
export const sendMail = (phoneNumber: string) => {
  return function (): void {
    const sendMailUrl = `${process.env.REACT_APP_API_URL}/api/requestDemo`;
    const data = {
      contact: phoneNumber,
      email: 'NA@NA.com',
      name: 'NA',
    }
    axios.post(sendMailUrl, data);
  };
};
export const updateSignUpToast = (showToast: boolean) => {
  return {
    type: SIGNUP_TOAST,
    payload: {
      showToast,
    },
  };
};
