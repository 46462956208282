import axios from 'axios';
import storage from 'redux-persist/es/storage';
import { HEADER_IGNORE_401 } from 'src/constants';
import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE } from '../constants';
import { checkOtpReset, fetchOtpReset } from './auth';
import { customerDataReset } from './customerDetails';
import { fetchLiveRatesReset } from './liveRates';
import { fetchProductionOrderReset } from './productionOrders';
import { fetchDataReset } from './viewQuotes';

export const fetchLogout = (redirectUrl?: string) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchLogoutRequest());
    const baseUrl = `${process.env.REACT_APP_API_URL}/apiuser/logout/`;
    const token = await storage.getItem('Token');
    dispatch(fetchDataReset());
    dispatch(fetchProductionOrderReset());
    dispatch(fetchOtpReset());
    dispatch(checkOtpReset(redirectUrl));
    dispatch(fetchLiveRatesReset());
    localStorage.clear();

    axios
      .get(baseUrl, {
        headers: { Authorization: `Bearer ${token}`, [HEADER_IGNORE_401]: 'true' },
      })
      .then((response) => {
        const confirmation = response;
        dispatch(fetchLogoutSuccess(confirmation));
        dispatch(customerDataReset());
      })
      .catch(() => {
        dispatch(fetchLogoutFailure());
      });
  };
};

export const fetchLogoutRequest = (): object => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const fetchLogoutSuccess = (confirmation: object): object => {
  return {
    type: LOGOUT_SUCCESS,
    payload: confirmation,
  };
};

export const fetchLogoutFailure = (): object => {
  return {
    type: LOGOUT_FAILURE,
  };
};
