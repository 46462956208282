import { CLOSE_QUERY_FAILURE, CLOSE_QUERY_REQUEST, CLOSE_QUERY_SUCCESS } from '../constants';

const initialState = {
  closeQueryLoadingStatus: false,
  closedQuerySuccessStatus: false,
};
interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const closeQueryReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CLOSE_QUERY_REQUEST:
    return {
      ...state,
      closeQueryLoadingStatus: true,
      closedQuerySuccessStatus: false,
    };

  case CLOSE_QUERY_SUCCESS:
    return {
      ...state,
      closeQueryLoadingStatus: false,
      closedQuerySuccessStatus: true,
    };
  case CLOSE_QUERY_FAILURE:
    return {
      ...state,
      closeQueryLoadingStatus: false,
      closedQuerySuccessStatus: false,
    };

  default:
    return initialState;
  }
};
