import { GET_CITIES_FAILURE,GET_CITIES_REQUEST,GET_CITIES_SUCCESS } from "../constants";

const initialState={
    citiesDetailsDataRequest:false,
    citiesDetail:{},
    citiesDetailsDataStatus:false
}

interface stateType{
    citiesDetailsDataRequest:boolean;
    citiesDetail:any;
    citiesDetailsDataStatus:boolean;
}

interface actionType{
    type:string,
    payload:{
        data:any;
    };
}

export const getCitiesReducer=(state=initialState,action:actionType): stateType => {
    switch(action.type){
        case GET_CITIES_REQUEST:
        return{
            ...state,
            citiesDetailsDataRequest:true,
            citiesDetail:{},
            citiesDetailsDataStatus:false,
        }
        case GET_CITIES_SUCCESS:
        return{
            ...state,
            citiesDetailsDataRequest:false,
            citiesDetail:action.payload.data,
            citiesDetailsDataStatus:true,
        }
        case GET_CITIES_FAILURE:
        return{
            ...state,
            citiesDetailsDataRequest:false,
            citiesDetail:{},
            citiesDetailsDataStatus:false,
        };
        default:
            return{
                ...state
            };
    }

};

