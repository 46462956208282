import { CREATE_PROVISIONAL_ORDER_REQUEST, CREATE_PROVISIONAL_ORDER_SUCCESS, CREATE_PROVISIONAL_ORDER_FAILURE } from '../constants';

const initialState = {
  createProvisionalOrderData:false,
  provisionalOrderDataResponse:{},
  CreateProvisionalOrderSatatus:false,
  provisionalOrderError:false,
};

interface stateType {
  createProvisionalOrderData: boolean;
  provisionalOrderDataResponse: Object;
  CreateProvisionalOrderSatatus: boolean;
  provisionalOrderError:boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const provisionalOrderCreationReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CREATE_PROVISIONAL_ORDER_REQUEST:
    return {
      ...state,
      createProvisionalOrderData:false,
      provisionalOrderDataResponse:{},
      CreateProvisionalOrderSatatus:false,
      provisionalOrderError:false,
      provisionalOrderRequest:true,
    };

  case CREATE_PROVISIONAL_ORDER_SUCCESS:
    return {
      ...state,
      success: true,
      createProvisionalOrderData:false,
      provisionalOrderDataResponse:action.payload.data,
      CreateProvisionalOrderSatatus:false,
      provisionalOrderError:false,
      provisionalOrderRequest:false,
    };
  case CREATE_PROVISIONAL_ORDER_FAILURE:
    return {
      createProvisionalOrderData:false,
      provisionalOrderDataResponse:{},
      CreateProvisionalOrderSatatus:false,
      provisionalOrderError:true,
      error: action?.payload,
      provisionalOrderRequest:false,
    };
    
  default:
    return {};
  }
};
