import { ADD_ADDRESS_FAILURE, ADD_ADDRESS_REQUEST, ADD_ADDRESS_SUCCESS, ADD_ADDRESS_RESET } from '../constants';

const initialState = {
  newAddressAddedDataStatus:false,
};

interface stateType {
  addCustomerNewAddressData: boolean;
  newAddressAddedData: Object;
  newAddressAddedDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const newAddressAddedReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case ADD_ADDRESS_REQUEST:
    return {
      ...state,
      newAddressAddedDataStatus:false,
    };

  case ADD_ADDRESS_SUCCESS:
    return {
      ...state,
      ...action?.payload,
      newAddressAddedDataStatus:true,
    };
  case ADD_ADDRESS_FAILURE:
    return {
      ...state,
      newAddressAddedDataStatus:false,
      error: action?.payload
    };
  case ADD_ADDRESS_RESET:
    return {
      ...state,
      newAddressAddedDataStatus:false,
    };
    
  default:
    return state
  }
};
