import { FETCH_CUSTOMER_DETAILS_FAILURE, FETCH_CUSTOMER_DETAILS_REQUEST, FETCH_CUSTOMER_DETAILS_SUCCESS } from './../constants';

const initialState = {
  customerDetailsViaGstRequest : false,
  customerDetailsViaGstData : {},
  customerDetailsViaGstStatus : false,
  customerOnboardViaGSTError:'',
}
  
  interface stateType {
   customerDetailsViaGstRequest : boolean;
    customerDetailsViaGstData : Object;
    customerDetailsViaGstStatus : boolean;
    customerOnboardViaGSTError: string;
}
  
  
  interface actionType {
      type : string;
      payload : {
          data : Object;
          error: string;
      }
  }
  
  
export const customerOnboardViaGstReducer = (state = initialState, action : actionType): stateType => {
    
  switch (action.type){
  case FETCH_CUSTOMER_DETAILS_REQUEST:
    return {
      ...state,
      customerDetailsViaGstRequest : true,
      customerDetailsViaGstData : {},
      customerDetailsViaGstStatus : false,
      customerOnboardViaGSTError: '',
    }
  
  case FETCH_CUSTOMER_DETAILS_SUCCESS:
    return {
      ...state,
      customerDetailsViaGstRequest : false,
      customerDetailsViaGstData : action.payload.data,
      customerDetailsViaGstStatus : true
    }
  case FETCH_CUSTOMER_DETAILS_FAILURE:    
    return {
      ...state,
      customerDetailsViaGstRequest : false,
      customerDetailsViaGstData : {},
      customerDetailsViaGstStatus : false,
      customerOnboardViaGSTError: action.payload.error,
    }
  default:
    return {
      ...state,
    }
  }
}