import { CREATE_CUSTOMER_FEEDBACK_REQUEST, CREATE_CUSTOMER_FEEDBACK_SUCCCESS, CREATE_CUSTOMER_FEEDBACK_FAILURE } from '../constants';

const initialState = {
  success: false
};
interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createCustomerFeedbackReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CREATE_CUSTOMER_FEEDBACK_REQUEST:
    return {};

  case CREATE_CUSTOMER_FEEDBACK_SUCCCESS:
    return {
      success: true
    };
  case CREATE_CUSTOMER_FEEDBACK_FAILURE:
    return {
      error: action?.payload
    };
    
  default:
    return {};
  }
};
