import { ChakraProvider, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkOtpReset, fetchOtpReset } from './redux/actions/auth';
import { fetchLogout } from './redux/actions/logout';
import { STATUS_CODE } from './redux/constants';
import { APP_LINK_EVENTS, HEADER_IGNORE_401, HEADER_IGNORE_403 } from 'src/constants';
import { ToastContainer, Slide } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Routes from './routes';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify.scss';
import { isWebView, sendAppLinkEvent } from './utils/generic';
import { Navigate } from './routes/constants';
const App = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const token = localStorage.getItem('Token');
        const { pathname: redirectUrl = '' } = history.location;
        const {
          response: { status },
          config: { headers }
        } = error;
        const FORBIDDEN_STATUS = !headers[HEADER_IGNORE_403] && status === STATUS_CODE.FORBIDDEN
        const UNAUTHORIZED_STATUS = !headers[HEADER_IGNORE_401] && status === STATUS_CODE.UNAUTHORIZED

        if (isWebView && [STATUS_CODE.FORBIDDEN, STATUS_CODE.UNAUTHORIZED].includes(status)) {
          sendAppLinkEvent(APP_LINK_EVENTS.Unauthorized);
          return Promise.reject(error);
        }

        if (token) {
          if (FORBIDDEN_STATUS || UNAUTHORIZED_STATUS) {
            dispatch(fetchLogout(redirectUrl));
            localStorage.clear();
            toast({
              description: 'You have been logged out, please login again.',
              position: 'top',
              status: 'error',
            });
            if (FORBIDDEN_STATUS) {
              dispatch(fetchLogout(redirectUrl));
            }
            if (UNAUTHORIZED_STATUS) {
              dispatch(fetchOtpReset());
              dispatch(checkOtpReset(redirectUrl));
            }
          }
        } else {
          if (!headers[HEADER_IGNORE_403] || !headers[HEADER_IGNORE_401]) {
            dispatch(checkOtpReset(redirectUrl));
            history.push(Navigate.login);
          }
        }
        
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Routes />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        className="toastStyles"
        transition={Slide}
      />
    </ChakraProvider>
  );
};
export default App;