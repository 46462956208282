import {
  FETCH_TOP_QUALITY_REQUEST,
  FETCH_TOP_QUALITY_FAILURE,
  FETCH_TOP_QUALITY_SUCCESS,
  FETCH_TOP_QUALITY_RESET,
  FETCH_INVENTORY_SALE_SUCCESS,
} from '../constants';
import { splitIntoChunk } from 'src/utils/generic';

const initialState = {
  topQualityDataRequest: false,
  topQualityData: { paginate: {} },
  topQualityDataStatus: false,
  records: [],
  allRecords: [],
  inventorySales: [],
};

interface stateType {
  isInventoryPill?: boolean;
  topQualityDataRequest: boolean;
  catId?: number | string,
  topQualityData: {
    paginate: Object;
  };
  topQualityDataStatus: boolean;
  records: any;
  allRecords: any;
  inventorySales: any;
}

interface actionType {
  type: string;
  payload: {
    data: {
      paginate: Object;
      records: Array<Object>;
    };
    isInventorySales?: boolean,
    fabricCategoryId?: number | string;
    reset: string;
  };
}

export const topQualityDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_TOP_QUALITY_REQUEST:
    return {
      ...state,
      topQualityDataRequest: true,
      topQualityData: { paginate: {} },
      topQualityDataStatus: false
    };

  case FETCH_TOP_QUALITY_SUCCESS: {
    const { records: oldRecords } = state;
    const { allRecords: oldAllRecords } = state;
    const newRecords = splitIntoChunk(action.payload.data.records);
    const catId = action.payload.fabricCategoryId;
    return {
      ...state,
      topQualityDataRequest: false,
      topQualityData: action.payload.data,
      topQualityDataStatus: true,
      records: [...oldRecords, ...newRecords],
      catId: catId,
      isInventoryPill: action.payload.isInventorySales,
      allRecords: !catId ? [...oldAllRecords, ...action.payload.data.records] : [...oldAllRecords]
    };
  }

  case FETCH_INVENTORY_SALE_SUCCESS: {
    return {
      ...state,
      inventorySales: action.payload.data.records,
    };
  }

  case FETCH_TOP_QUALITY_FAILURE:
    return {
      ...state,
      topQualityDataRequest: false,
      topQualityData: { paginate: {} },
      topQualityDataStatus: false,
      records: [],
      allRecords: []
    };
  case FETCH_TOP_QUALITY_RESET: {
    const { allRecords: oldAllRecords } = state;
    const { reset } = action.payload;
    return {
      ...state,
      topQualityDataRequest: false,
      topQualityData: { paginate: {} },
      topQualityDataStatus: false,
      records: [],
      allRecords: reset === 'all' ? [] : [...oldAllRecords]
    };
  }
  default:
    return {
      ...state
    };
  }
};
