import {
  FETCH_ADD_GST_REQUEST,
  FETCH_ADD_GST_SUCCESS,
  FETCH_ADD_GST_FAILURE,
  FETCH_ADD_GST_RESET
} from '../constants';

const initialState = {
  addGstRequest: false,
  addGstData: {},
  addGstStatus: false
};

interface stateType {
  addGstRequest: boolean;
  addGstData: Object;
  addGstStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const addGstSReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_ADD_GST_REQUEST:
    return {
      ...state,
      addGstRequest: true,
      addGstData: {},
      addGstStatus: false
    };
  case FETCH_ADD_GST_SUCCESS:
    return {
      ...state,
      addGstRequest: false,
      addGstData: action.payload.data,
      addGstStatus: true
    };
  case FETCH_ADD_GST_FAILURE:
    return {
      ...state,
      addGstRequest: false,
      addGstData: {},
      addGstStatus: false
    };
  case FETCH_ADD_GST_RESET:
    return initialState;

  default:
    return state;
  }
};
