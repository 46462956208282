import {
  FETCH_CUSTOMER_VPA_REQUEST,
  FETCH_CUSTOMER_VPA_SUCCESS,
  FETCH_CUSTOMER_VPA_FAILURE
} from '../constants';

const initialState = {
  VPADataRequest: false,
  VPAData: {},
  VPADataStatus: false
};

interface stateType {
  VPADataRequest: boolean;
  VPAData: Object;
  VPADataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const customerVPAReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_CUSTOMER_VPA_REQUEST:
    return {
      ...state,
      VPADataRequest: true,
      VPAData: {},
      VPADataStatus: false
    };

  case FETCH_CUSTOMER_VPA_SUCCESS:
    return {
      ...state,
      VPADataRequest: false,
      VPAData: action.payload.data,
      VPADataStatus: true
    };
  case FETCH_CUSTOMER_VPA_FAILURE:
    return {
      ...state,
      VPADataRequest: false,
      VPAData: {},
      VPADataStatus: false
    };
  default:
    return state;
  }
};
