import axios from 'axios';
import storage from 'redux-persist/es/storage';
import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_QUERY_MATERIAL_REQUEST,
  FETCH_QUERY_MATERIAL_SUCCESS,
  FETCH_QUERY_MATERIAL_FAILURE,
  CUSTOMER_DATA_CLEAR
} from '../constants';

export const fetchCustomerData = (brandId: number) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchCustomerRequest());
    const baseUrl = `${process.env.REACT_APP_API_URL}/apiuser/customer/${brandId}/`
    const token = await storage.getItem('Token');

    axios
      .get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {        
        storage.setItem('verificationStatus', response.data.resp.data[0].verificationStatus);
        dispatch(fetchCustomerSuccess(response.data.resp.data[0]));
      })
      .catch((error) => {
        dispatch(fetchCustomerFailure(error));
      });
  };
};

export const fetchCustomerRequest = (): Object => {
  return {
    type: FETCH_CUSTOMER_REQUEST
  };
};

export const fetchCustomerSuccess = (orderData: Object): object => {
  const { isInternational }: any = orderData;
  storage.setItem('isInternational', isInternational);
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: {
      data: orderData
    }
  };
};

export const fetchCustomerFailure = (error: string): Object => {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    payload: {
      error
    }
  };
};

export const customerDataReset = (): object => {
  return {
    type: CUSTOMER_DATA_CLEAR,
  };
};

// Fetch customer material data
export const fetchQueryMaterialData = (brandId: number) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchQueryMaterialDataRequest());
    
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/material/options`
    const token = await storage.getItem('Token');

    axios
      .get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params : {
          brandId,
          types: ['fabricCategory','fabricSubCategory','certification','currency','unitByType']
        }
      })
      .then((response) => {
        dispatch(fetchQueryMaterialDataSuccess(response.data.resp.data));
      })
      .catch(() => {
        dispatch(fetchQueryMaterialDataFailure());
      });
  };
};

export const fetchQueryMaterialDataRequest = (): Object => {
  return {
    type: FETCH_QUERY_MATERIAL_REQUEST
  };
};

export const fetchQueryMaterialDataSuccess = (orderData: Object): object => {
  return {
    type: FETCH_QUERY_MATERIAL_SUCCESS,
    payload: {
      data: orderData
    }
  };
};

export const fetchQueryMaterialDataFailure = (): Object => {
  return {
    type: FETCH_QUERY_MATERIAL_FAILURE
  };
};