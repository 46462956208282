import { CREATE_PROVISIONAL_ORDER_REQUEST, CREATE_PROVISIONAL_ORDER_SUCCESS, CREATE_PROVISIONAL_ORDER_FAILURE, CREATE_RAZORPAY_ORDER_REQUEST, CREATE_RAZORPAY_ORDER_SUCCESS, CREATE_RAZORPAY_ORDER_FAILURE } from '../constants';

const initialState = {
  createRazorpayOrderData:false,
  razorPayOrderDataResponse:{},
  razorPayOrderStatus:false
};

interface stateType {
  createRazorpayOrderData: boolean;
  razorPayOrderDataResponse: Object;
  razorPayOrderStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const razorPayOrderCreationReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case CREATE_RAZORPAY_ORDER_REQUEST:
    return {
      ...state,
      createRazorpayOrderData:false,
      razorPayOrderDataResponse:{},
      razorPayOrderStatus:false
    };

  case CREATE_RAZORPAY_ORDER_SUCCESS:
    return {
      ...state,
      success: true,
      createRazorpayOrderData:false,
      razorPayOrderDataResponse:action.payload.data,
      razorPayOrderStatus:false
    };
  case CREATE_RAZORPAY_ORDER_FAILURE:
    return {
      createRazorpayOrderData:false,
      razorPayOrderDataResponse:{},
      razorPayOrderStatus:false,
      error: action?.payload
    };
    
  default:
    return {};
  }
};
