export const QUOTE_CATEGORY_TYPE = {
  GREIGE: 1,
  JOB_WORK: 2,
  BUNDLED: 3,
  YARN_DYED: 4,
  DENIM: 5,
};

export const PRODUCTION_SUB_TYPE = {
  Greige: 1,
  YarnDyed: 2,
  Rfd: 3,
  Dyed: 4,
  Printed: 5,
  Denim: 6
};

export const QUOTE_SUBMISSION_TYPE = {
  XFactory: 1,
  Landed: 2,
  Fob: 3,
  Cnf: 4,
};
  
export const QUOTE_CATEGORY_TYPE_REVERSE = {
  [QUOTE_CATEGORY_TYPE.BUNDLED]: 'Bundled',
  [QUOTE_CATEGORY_TYPE.JOB_WORK]: 'Job work',
  [QUOTE_CATEGORY_TYPE.GREIGE]: 'Greige',
  [QUOTE_CATEGORY_TYPE.YARN_DYED]: 'Yarn dyed',
  [QUOTE_CATEGORY_TYPE.DENIM]: 'Denim',
};

export const QUOTE_SUBMISSION_TYPE_REVERSE = {
  [QUOTE_SUBMISSION_TYPE.XFactory]: 'x-factory',
  [QUOTE_SUBMISSION_TYPE.Landed]: 'Landed',
  [QUOTE_SUBMISSION_TYPE.Fob]: 'FOB',
  [QUOTE_SUBMISSION_TYPE.Cnf]: 'CNF',
};
