import { FETCH_METRICS_FAILURE, FETCH_METRICS_REQUEST, FETCH_METRICS_SUCCESS } from '../constants';

const initialState = {
  metrics: {},
  metricsRequest: false,
  metricsFetchStatus: false,
  metricsFetchError: false
};

interface actionTypes {
  type: string;
  payload: {
    metrics: object;
  };
}

interface stateTypes {
  metrics: object;
  metricsRequest: boolean;
  metricsFetchStatus: boolean;
  metricsFetchError: boolean;
}

export const homeReducer = (state = initialState, action: actionTypes): stateTypes => {
  switch (action.type) {
  case FETCH_METRICS_REQUEST:
    return {
      ...state,
      metricsRequest: true
    };

  case FETCH_METRICS_SUCCESS:
    return {
      ...state,
      metricsRequest: false,
      metricsFetchStatus: true,
      metrics: action.payload.metrics
    };

  case FETCH_METRICS_FAILURE:
    return {
      ...state,
      metricsFetchError: true,
      metricsFetchStatus: false,
      metricsRequest: false
    };

  default:
    return { ...state };
  }
};
