import {
  FETCH_PRODUCTION_DETAILS_REQUEST,
  FETCH_PRODUCTION_DETAILS_SUCCESS,
  FETCH_PRODUCTION_DETAILS_FAILURE
} from '../constants';

const initialState = {
  productionOrderDataRequest: false,
  productionOrderData: {},
  productionOrderDataStatus: false
};

interface stateType {
  productionOrderDataRequest: boolean;
  productionOrderData: Object;
  productionOrderDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const productionOrderDetailsReducer = (
  state = initialState,
  action: actionType
): stateType => {
  switch (action.type) {
  case FETCH_PRODUCTION_DETAILS_REQUEST:
    return {
      ...state,
      productionOrderDataRequest: true,
      productionOrderData: {},
      productionOrderDataStatus: false
    };
  case FETCH_PRODUCTION_DETAILS_SUCCESS:
    return {
      ...state,
      productionOrderDataRequest: false,
      productionOrderData: action.payload.data,
      productionOrderDataStatus: true
    };
  case FETCH_PRODUCTION_DETAILS_FAILURE:
    return {
      ...state,
      productionOrderDataRequest: false,
      productionOrderData: {},
      productionOrderDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
