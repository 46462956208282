export const STEP_1 = '1';
export const STEP_2 = '2';
export const STEP_3 = '3';
export const STEP_4 = '4';
export const STEP_5 = '5';
export const STEP_6 = '6';
export const STEP_7 = '7';
export const STEP_8 = '8';
export const POLYESTER_LABEL = 'Polyester';

export const POLYESTER_STEPS: any = {
  [STEP_1]: {
    prev: null,
    next: STEP_2,
    keys: ['category'],
    title: 'Fabric type',
  },
  [STEP_2]: {
    prev: STEP_1,
    next: STEP_3,
    keys: ['compositionType', 'compositions'],
    title: 'Composition',
  },
  [STEP_3]: {
    prev: STEP_2,
    next: STEP_4,
    keys: ['productionSubType'],
    title: 'Choose a Subcategory',
  },
  [STEP_4]: {
    prev: STEP_3,
    next: STEP_5,
    keys: [
      'requirementType',
      'finishedFabricType',
      'numberOfColors',
      'color',
      'productionSubTypeAttributeId',
    ],
    title: 'Requirement type',
  },
  [STEP_5]: {
    prev: STEP_4,
    next: STEP_6,
    keys: ['width'],
    title: 'Minimum width',
  },
  [STEP_6]: {
    prev: STEP_5,
    next: STEP_7,
    keys: ['weight', 'warp', 'weft', 'epi', 'ppi'],
    title: 'Quality specifications',
  },
  [STEP_7]: {
    prev: STEP_6,
    next: STEP_8,
    keys: ['loomType'],
    title: 'Loom type',
  },
  [STEP_8]: {
    prev: STEP_7,
    next: null,
    keys: ['creditPeriod'],
    title: 'Payment type',
  },
};

export const POLYESTER_COMPOSITION_TYPES: { label: string; value: string }[] = [
  {
    label: '100% Polyester',
    value: '115',
  },
  {
    label: 'Blend',
    value: '116',
  },
];

export const WEIGHT_TYPES = [
  {
    name: 'GSM',
    id: 10,
  },
  {
    name: 'GLM',
    id: 11,
  },
];

export const POLYESTER_WIDTHS = ['42', '54', '58', '68'];

export const POLYESTER_LOOM_TYPES: { label: string; value: string }[] = [
  {
    label: 'Water Jet',
    value: '4',
  },
  {
    label: 'Power Loom',
    value: '1',
  },
];

export const POLYESTER_CERTIFICATIONS: { label: string; value: number }[] = [
  {
    label: 'RCS',
    value: 17,
  },
  {
    label: 'GRS',
    value: 9,
  },
];
