import {
  FETCH_LIVE_RATES_REQUEST,
  FETCH_LIVE_RATES_SUCCESS,
  FETCH_LIVE_RATES_FAILURE,
  FETCH_LIVE_RATES_RESET,
} from '../constants';

const initialState = {
  liveRatesDataRequest: false,
  liveRatesData: { paginate: {}, records: [] },
  liveRatesDataStatus: false,
  records: [],
};
interface stateType {
  liveRatesDataRequest: boolean;
  liveRatesData: {
    paginate: Object;
  };
  liveRatesDataStatus: boolean;
  records: any;
}
interface actionType {
  type: string;
  payload: {
    data: {
      paginate: Object;
      records: Array<Object>;
    };
  };
}
export const liveRatesDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_LIVE_RATES_REQUEST:
    return {
      ...state,
      liveRatesDataRequest: true,
      liveRatesData: { paginate: {} },
      liveRatesDataStatus: false,
    };

  case FETCH_LIVE_RATES_SUCCESS: {
    const { records } = state;
    return {
      ...state,
      liveRatesDataRequest: false,
      liveRatesData: action.payload.data,
      liveRatesDataStatus: true,
      records: [...records, ...action.payload.data.records],
    };
  }

  case FETCH_LIVE_RATES_FAILURE:
    return {
      ...state,
      liveRatesDataRequest: false,
      liveRatesData: { paginate: {} },
      liveRatesDataStatus: false,
      records: [],
    };
  case FETCH_LIVE_RATES_RESET: {
    return {
      ...state,
      liveRatesDataRequest: false,
      liveRatesData: { paginate: {} },
      liveRatesDataStatus: false,
      records: [],
    };
  }
  default:
    return {
      ...state,
    };
  }
};
