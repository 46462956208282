import { APPROVAL_REFERENCE_CODE_FAILURE, APPROVAL_REFERENCE_CODE_REQUEST, APPROVAL_REFERENCE_CODE_SUCCCESS } from '../constants';

const initialState = {
  bitLoomStatus: false,
  bitLoomData: {},
  readingOtpStatus: false,
  error:false,
  errorMsg:''
};

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const referenceCodeReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case APPROVAL_REFERENCE_CODE_REQUEST:
    return {
      ...state,
      bitLoomStatus: false,
      bitLoomData: {},
      readingOtpStatus: true,
      error:false,
      errorMsg:'',
    };

  case APPROVAL_REFERENCE_CODE_SUCCCESS:
    return {
      ...state,
      bitLoomStatus: true,
      bitLoomData: action.payload.data,
      readingOtpStatus: false,
      error:false,
      errorMsg:''
    };
  case APPROVAL_REFERENCE_CODE_FAILURE:
    return {
      ...state,
      bitLoomStatus: false,
      errorMsg:action.payload,
      readingOtpStatus: false,
      error:true,
    };
  default:
    return {};
  }
};
