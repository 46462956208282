import { FunctionComponent, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { reactPagePropsType } from 'src/interfaces/reactPageProps';
import { RootState } from 'src/redux/reducers';
import { Navigate } from './constants';

interface ProtectedRouteTypes {
  path: string;
  component: FunctionComponent<reactPagePropsType>;
  exact: boolean;
}

const ProtectedRoute = ({ path, component, exact }: ProtectedRouteTypes): ReactElement => {
  const login = useSelector((state: RootState) => {
    return state.login;
  });

  const { loggedIn, redirectUrl } = login;

  if (!loggedIn) {
    const loginRedirectionPath = `/${window.location?.href?.split('/').splice(3)?.join('/')}`
    const isValidRedirection = loginRedirectionPath && loginRedirectionPath !== Navigate.account

    if (redirectUrl) {
      return <Redirect to={`${Navigate.login}/?redirect=${redirectUrl}`} />;
    } else {
      return <Redirect to={`${Navigate.login}${isValidRedirection ? `/?redirect=${loginRedirectionPath}` : ''}`} />;
    }
  }

  return <Route path={path} component={component} exact={exact} />;
};

export default ProtectedRoute;
