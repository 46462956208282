import { RAISE_FABRIC_ISSUE_REQUEST, RAISE_FABRIC_ISSUE_SUCCESS, RAISE_FABRIC_ISSUE_FAILURE } from '../constants';

const initialState = {
  isLoading: false,
};

interface stateType {
  success?: boolean;
  isLoading?: boolean;
  error?: {
    data?: {
      message?: string
  }
  };
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const raiseFabricIssueReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case RAISE_FABRIC_ISSUE_REQUEST:
    return {
      isLoading: true
    };

  case RAISE_FABRIC_ISSUE_SUCCESS:
    return {
      success: true,
      isLoading: false,
    };
  case RAISE_FABRIC_ISSUE_FAILURE:
    return {
      error: action?.payload,
      isLoading:false
    };
    
  default:
    return {};
  }
};
