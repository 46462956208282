import axios from 'axios';
import storage from 'redux-persist/es/storage';
import {
  FETCH_PRODUCTION_ORDER_REQUEST,
  FETCH_PRODUCTION_ORDER_SUCCESS,
  FETCH_PRODUCTION_ORDER_FAILURE,
  FETCH_PRODUCTION_ORDER_RESET
} from '../constants';
import { fetchOrderTnaReset } from './orderTna';

export const fetchProductionOrder = (orderTab: string, offset?: string) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchProductionOrderRequest());
    dispatch(fetchOrderTnaReset());
    const baseUrl = `${process.env.REACT_APP_API_URL}/apifabric/orders`;
    const token = await storage.getItem('Token');
    const params = {
      orderTab,
      offset
    };

    axios
      .get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      })
      .then((response) => {
        dispatch(fetchProductionOrderSuccess(response.data.data, orderTab));
      })
      .catch((error) => {
        dispatch(fetchProductionOrderFailure(error, orderTab));
      });
  };
};

export const fetchProductionOrderRequest = (): Object => {
  return {
    type: FETCH_PRODUCTION_ORDER_REQUEST
  };
};

export const fetchProductionOrderSuccess = (productionOrders: Object, orderTab: string): object => {
  return {
    type: FETCH_PRODUCTION_ORDER_SUCCESS,
    payload: {
      data: productionOrders,
      orderTab
    }
  };
};

export const fetchProductionOrderFailure = (error: string, orderTab: string): Object => {
  return {
    type: FETCH_PRODUCTION_ORDER_FAILURE,
    payload: {
      error,
      orderTab
    }
  };
};

export const fetchProductionOrderReset = (): Object => {
  return {
    type: FETCH_PRODUCTION_ORDER_RESET
  };
};
