import { GET_ADDRESS_FAILURE, GET_ADDRESS_REQUEST, GET_ADDRESS_SUCCESS } from '../constants';

const initialState = {
  addressDetailsDataRequest: false,
  AllAddressDetailsData: {},
  AllAddressDetailsDataStatus: false
};

interface stateType {
  addressDetailsDataRequest: boolean;
  AllAddressDetailsData: Object;
  AllAddressDetailsDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const getAllAddressReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case GET_ADDRESS_REQUEST:
    return {
      ...state,
      addressDetailsDataRequest: true,
      AllAddressDetailsData: {},
      AllAddressDetailsDataStatus: false
    };
  case GET_ADDRESS_SUCCESS:
    return {
      ...state,
      addressDetailsDataRequest: false,
      AllAddressDetailsData: action.payload.data,
      AllAddressDetailsDataStatus: true,
    };
  case GET_ADDRESS_FAILURE:
    return {
      ...state,
      addressDetailsDataRequest: false,
      AllAddressDetailsData: {},
      AllAddressDetailsDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
