import {
  FETCH_GST_NUMBER_REQUEST,
  FETCH_GST_NUMBER_SUCCESS,
  FETCH_GST_NUMBER_FAILURE,
  GST_NUBER_RESET
} from '../constants';

const initialState = {
  gstVerificationDataRequest: false,
  gstVerificationData: { tradeName: '' },
  gstVerificationDataStatus: false,
  gstNumberVerificationDataError: false,
  gstNumberError: ''
};

interface stateType {
  gstVerificationDataRequest: boolean;
  gstVerificationData: { tradeName: string };
  gstVerificationDataStatus: boolean;
  gstNumberVerificationDataError: boolean;
  gstNumberError: string;
}
interface actionType {
  type: string;
  payload: {
    data: any;
    error: string;
  };
}

export const gstNumberVerificationReducer = (
  state = initialState,
  action: actionType
): stateType => {
  switch (action.type) {
  case FETCH_GST_NUMBER_REQUEST:
    return {
      ...state,
      gstVerificationDataRequest: true,
      gstVerificationData: { tradeName: '' },
      gstVerificationDataStatus: false
    };

  case FETCH_GST_NUMBER_SUCCESS:
    return {
      ...state,
      gstVerificationDataRequest: false,
      gstVerificationData: action.payload.data.data,
      gstVerificationDataStatus: true,
      gstNumberVerificationDataError: false
    };
  case FETCH_GST_NUMBER_FAILURE:
    return {
      ...state,
      gstVerificationDataRequest: false,
      gstVerificationData: { tradeName: '' },
      gstVerificationDataStatus: false,
      gstNumberVerificationDataError: true,
      gstNumberError: action.payload.error
    };
  case GST_NUBER_RESET:
    return {
      ...state,
      gstVerificationDataRequest: false,
      gstVerificationData: { tradeName: '' },
      gstVerificationDataStatus: false,
      gstNumberVerificationDataError: false,
      gstNumberError: ''
    };
  default:
    return state;
  }
};
