import { lazy } from 'react';
import { RouteName } from './constants';

const Login = lazy(() => import('src/pages/auth/Login'));
const NotRegistered = lazy(() => import('src/components/NotRegistered'));
const Terms = lazy(() => import('src/components/Terms'));
const HomeScreen = lazy(() => import('src/pages/HomeScreen'));
const Quotes = lazy(() => import('src/pages/Quotes'));
const QuoteDetails = lazy(() => import('src/pages/QuoteDetails'));
const Profile = lazy(() => import('src/pages/Account/Profile'));
const Orders = lazy(() => import('src/pages/Orders'));
const Payments = lazy(() => import('src/pages/Payments'));
const Search = lazy(() => import('src/pages/Search'));
const IntransitDetails = lazy(() => import('src/pages/IntransitDetails'));
const RaiseIssue = lazy(() => import('src/pages/RaiseIssue'));
const DeliveredDetails = lazy(() => import('src/pages/IntransitDetails'));
const ProductionDetail = lazy(() => import('src/pages/OrderProductionDetails'));
const Summary = lazy(() => import('src/pages/Account/Summary'));
const createCustomer = lazy(() => import('src/pages/CreateCustomer'));
const OTP = lazy(() => import('src/pages/auth/OTP'));
const RequestCreate = lazy(() => import('src/pages/RequestCreate'));
const FeedBack = lazy(() => import('src/pages/FeedBack'));
const TopSelling = lazy(() => import('src/pages/TopSelling'));
const Editspecifications = lazy(() => import('src/pages/EditSpecifications'));
const CreateOrder = lazy(() => import('src/pages/Orders/CreateOrder'));
const InternationalPayment =lazy(()=>import('src/pages/Orders/CreateOrder/PaymentInternational'))
const CreateOrderConfirmDetails = lazy(
  () => import('src/pages/Orders/CreateOrder/ConfirmOrderDetails'),
);
const LiveQualitiesDetailsView = lazy(
  () => import('src/pages/LiveQualityDetailsView'),
);
const LiveQualitiesOrderConfirmDetails = lazy(
  () => import('src/pages/Orders/CreateOrder/ConfirmOrderDetails'),
);
const TopSellingCreateOrderConfirmDetails = lazy(
  () => import('src/pages/Orders/CreateOrder/ConfirmOrderDetails'),
);
const CreateOrderPaymentDetails = lazy(() => import('src/pages/Orders/CreateOrder/PaymentTerms'));
const AppLink = lazy(() => import('src/pages/AppLink'));

export const routesArray = [
  {
    isProtected: false,
    path: RouteName.login,
    Component: Login,
  },
  {
    isProtected: false,
    path: RouteName.notregistered,
    Component: NotRegistered,
  },
  {
    isProtected: true,
    path: RouteName.homescreen,
    Component: HomeScreen,
  },
  {
    isProtected: true,
    path: RouteName.topSelling,
    Component: TopSelling,
  },
  {
    isProtected: true,
    path: RouteName.quotes,
    Component: Quotes,
  },
  {
    isProtected: false,
    path: RouteName.terms,
    Component: Terms,
  },
  {
    isProtected: true,
    path: RouteName.quotedetails,
    Component: QuoteDetails,
  },
  {
    isProtected: true,
    path: RouteName.profile,
    Component: Profile,
  },
  {
    isProtected: true,
    path: RouteName.payments,
    Component: Payments,
  },
  {
    isProtected: true,
    path: RouteName.search,
    Component: Search,
  },
  {
    isProtected: true,
    path: RouteName.orders,
    Component: Orders,
  },
  {
    isProtected: true,
    path: RouteName.intransitdetails,
    Component: IntransitDetails,
  },
  {
    isProtected: true,
    path: RouteName.raiseIssue,
    Component: RaiseIssue,
  },
  {
    isProtected: true,
    path: RouteName.productiondetails,
    Component: ProductionDetail,
  },
  {
    isProtected: true,
    path: RouteName.account,
    Component: Summary,
  },
  {
    isProtected: false,
    path: RouteName.createCustomer,
    Component: createCustomer,
  },
  {
    isProtected: false,
    path: `${RouteName.otp}/:number`,
    Component: OTP,
  },
  {
    isProtected: true,
    path: RouteName.requestcreate,
    Component: RequestCreate,
  },
  {
    isProtected: true,
    path: RouteName.editspecifications,
    Component: Editspecifications,
  },
  {
    isProtected: true,
    path: RouteName.deliveredDetails,
    Component: DeliveredDetails,
  },
  {
    isProtected: true,
    path: RouteName.feedBack,
    Component: FeedBack,
  },
  {
    isProtected: true,
    path: RouteName.createOrder,
    Component: CreateOrder,
  },
  {
    isProtected: true,
    path: RouteName.CreateOrderConfirmDetails,
    Component: CreateOrderConfirmDetails,
  },
  {
    isProtected: true,
    path: RouteName.LiveQualityConfirmDetails,
    Component: LiveQualitiesDetailsView,
  },
  {
    isProtected: true,
    path: RouteName.liveQualityCreateOrderConfirmDetails,
    Component: LiveQualitiesOrderConfirmDetails,
  },
  {
    isProtected: true,
    path: RouteName.topSellingreateOrderConfirmDetails,
    Component: TopSellingCreateOrderConfirmDetails,
  },
  {
    isProtected: true,
    path: RouteName.CreateOrderPaymentDetails,
    Component: CreateOrderPaymentDetails,
  },
  {
    isProtected: false,
    path: RouteName.appLink,
    Component: AppLink,
  },
  {
    isProtected:false,
    path:RouteName.PaymentInternational,
    Component:InternationalPayment,
  }
];
