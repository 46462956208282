import { ORDER_TAB } from 'src/constants';
import {
  FETCH_PRODUCTION_ORDER_REQUEST,
  FETCH_PRODUCTION_ORDER_SUCCESS,
  FETCH_PRODUCTION_ORDER_FAILURE,
  FETCH_PRODUCTION_ORDER_RESET,
} from '../constants';

const emptyRecordsObject = {
  [ORDER_TAB.PRODUCTION]: [],
  [ORDER_TAB.IN_TRANSIT]: [],
  [ORDER_TAB.DELIVERED]: [],
};

const emptyProductionOrderData = {
  [ORDER_TAB.PRODUCTION]: {},
  [ORDER_TAB.IN_TRANSIT]: {},
  [ORDER_TAB.DELIVERED]: {},
};
const initialState = {
  productionOrderDataRequest: false,
  productionOrderData: emptyProductionOrderData,
  productionOrderDataStatus: false,
  recordsObject: emptyRecordsObject,
};
interface stateType {
  productionOrderDataRequest: boolean;
  productionOrderData: { [key: string]: any };
  productionOrderDataStatus: boolean;
  recordsObject: { [key: string]: any };
}

interface actionType {
  type: string;
  payload: {
    orderTab: string;
    data: {
      records: Array<Object>;
    };
  };
}

export const productionOrdersReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_PRODUCTION_ORDER_REQUEST:
    return {
      ...state,
      productionOrderDataRequest: true,
      productionOrderDataStatus: false,
    };

  case FETCH_PRODUCTION_ORDER_SUCCESS: {
    const { recordsObject } = state;
    const oldData = recordsObject[action.payload.orderTab as keyof typeof recordsObject];
    return {
      ...state,
      productionOrderDataRequest: false,
      productionOrderData: {
        ...state.productionOrderData,
        [action.payload.orderTab]: action.payload.data,
      },
      productionOrderDataStatus: true,
      recordsObject: {
        ...state.recordsObject,
        [action.payload.orderTab]: [...oldData, ...action.payload.data.records],
      },
    };
  }
  case FETCH_PRODUCTION_ORDER_FAILURE: {
    return {
      ...state,
      productionOrderDataRequest: false,
      productionOrderData: emptyProductionOrderData,
      productionOrderDataStatus: false,
      recordsObject: emptyRecordsObject,
    };
  }
  case FETCH_PRODUCTION_ORDER_RESET:
    return {
      ...state,
      productionOrderDataRequest: false,
      productionOrderData: emptyProductionOrderData,
      productionOrderDataStatus: false,
      recordsObject: emptyRecordsObject,
    };
  default:
    return {
      ...state,
    };
  }
};
