import {
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_LOCATION_CLEAR
} from '../constants';
  
const initialState = {
  locationDetailsDataRequest: false,
  locationDetailsData: {},
  locationDetailsDataStatus: false,
  pincodeError:'',
};
  
  interface stateType {
    locationDetailsDataRequest: boolean,
  locationDetailsData: Object,
  locationDetailsDataStatus: boolean,
  pincodeError:String,
  }
  
  interface actionType {
    type: string;
    payload: {
      data: Object;
      error: { message: string; code: number };
      message: string;

        };
  }
  
export const getLocationDetailsReducer = (
  state = initialState,
  action: actionType
): stateType => {
  switch (action.type) {
  case GET_LOCATION_REQUEST:
    return {
      ...state,
      locationDetailsDataRequest: true,
      locationDetailsData: {},
      locationDetailsDataStatus: false,
      pincodeError:'',
    };
  case GET_LOCATION_SUCCESS:
    return {
      ...state,
      locationDetailsDataRequest: false,
      locationDetailsData: action.payload.data,
      locationDetailsDataStatus: true,
      pincodeError:'',
    };
  case GET_LOCATION_FAILURE:     
    return {
      ...state,
      locationDetailsDataRequest: false,
      locationDetailsData: {},
      locationDetailsDataStatus: false,
      pincodeError:action.payload.message,
    };
  case GET_LOCATION_CLEAR:     
    return {
      ...state,
      locationDetailsDataRequest: false,
      locationDetailsData: {},
      locationDetailsDataStatus: false,
      pincodeError:'',
    };
  default:
    return {
      ...state
    };
  }
};
  