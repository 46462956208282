import axios from 'axios';
import storage from 'redux-persist/es/storage';
import {
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_RESET,
  FETCH_DATA_SUCCESS
} from '../constants';

export const fetchQuotesData = (quoteTab: string, offset?: string) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchDataRequest());
    const params = {
      requestTab: quoteTab,
      offset
    };
    const baseUrl = `${process.env.REACT_APP_API_URL}/apifabric/customer/fabrics`;
    const token = await storage.getItem('Token');
    axios
      .get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      })
      .then((response) => {
        dispatch(fetchDataSuccess(response.data.data, quoteTab));
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error, quoteTab));
      });
  };
};

export const fetchDataRequest = (): Object => {
  return {
    type: FETCH_DATA_REQUEST
  };
};

export const fetchDataSuccess = (quotesData: Object, quoteTab: string): object => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: {
      data: quotesData,
      quoteTab,
    }
  };
};

export const fetchDataFailure = (error: string, quoteTab: string): Object => {
  return {
    type: FETCH_DATA_FAILURE,
    payload: {
      error,
      quoteTab,
    }
  };
};

export const fetchDataReset = (): Object => {
  return {
    type: FETCH_DATA_RESET
  };
};
