import {
  FETCH_FILTERED_LIVE_RATES_REQUEST,
  FETCH_FILTERED_LIVE_RATES_SUCCESS,
  FETCH_FILTERED_LIVE_RATES_FAILURE,
  FETCH_FILTERED_LIVE_RATES_RESET,
} from '../constants';

const initialState = {
  filteredLiveRatesDataRequest: false,
  filteredLiveRatesData: { paginate: {}, records: [] },
  filteredLiveRatesDataStatus: false,
  records: [],
};
  interface stateType {
    filteredLiveRatesDataRequest: boolean;
    filteredLiveRatesData: {
      paginate: Object;
    };
    filteredLiveRatesDataStatus: boolean;
    records: any;
  }
  interface actionType {
    type: string;
    payload: {
      data: {
        paginate: Object;
        records: Array<Object>;
      };
    };
  }
export const filteredLiveRatesDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_FILTERED_LIVE_RATES_REQUEST:
    return {
      ...state,
      filteredLiveRatesDataRequest: true,
      filteredLiveRatesData: { paginate: {} },
      filteredLiveRatesDataStatus: false,
    };
  
  case FETCH_FILTERED_LIVE_RATES_SUCCESS: {
    const { records } = state;
    return {
      ...state,
      filteredLiveRatesDataRequest: false,
      filteredLiveRatesData: action.payload.data,
      filteredLiveRatesDataStatus: true,
      records: [...records, ...action.payload.data.records],
      // records: [action.payload.data.records],
    };
  }
  
  case FETCH_FILTERED_LIVE_RATES_FAILURE:
    return {
      ...state,
      filteredLiveRatesDataRequest: false,
      filteredLiveRatesData: { paginate: {} },
      filteredLiveRatesDataStatus: false,
      records: [],
    };
  case FETCH_FILTERED_LIVE_RATES_RESET: {
    return {
      ...state,
      filteredLiveRatesDataRequest: false,
      filteredLiveRatesData: { paginate: {} },
      filteredLiveRatesDataStatus: false,
      records: [],
    };
  }
  default:
    return {
      ...state,
    };
  }
};
  