import {
  FETCH_QUOTE_DETAILS_FAILURE,
  FETCH_QUOTE_DETAILS_REQUEST,
  FETCH_QUOTE_DETAILS_SUCCESS
} from '../constants';

const initialState = {
  quotes: {},
  quoteDetailsRequest: false,
  quoteDetailsFetchStatus: false,
  quoteDetailsFetchError: false,
  error: ''
};

interface actionTypes {
  type: string;
  payload: {
    data: { quotes: object };
    error: string;
  };
}

interface stateTypes {
  quotes: {
    All?: Array<object>;
  };
  quoteDetailsRequest: boolean;
  quoteDetailsFetchStatus: boolean;
  quoteDetailsFetchError: boolean;
  error: string;
}

export const quoteDetailsReducer = (state = initialState, action: actionTypes): stateTypes => {
  switch (action.type) {
  case FETCH_QUOTE_DETAILS_REQUEST:
    return {
      ...state,
      quoteDetailsRequest: true
    };

  case FETCH_QUOTE_DETAILS_SUCCESS:
    return {
      ...state,
      quoteDetailsRequest: false,
      quoteDetailsFetchStatus: true,
      quotes: action.payload.data.quotes
    };

  case FETCH_QUOTE_DETAILS_FAILURE:
    return {
      ...state,
      quoteDetailsFetchError: true,
      quoteDetailsFetchStatus: false,
      quoteDetailsRequest: false,
      error: action.payload.error
    };

  default:
    return { ...state };
  }
};
