import {
  FETCH_RETURN_AND_REFUND_POLICY_REQUEST,
  FETCH_RETURN_AND_REFUND_POLICY_SUCCESS,
  FETCH_RETURN_AND_REFUND_POLICY_FAILURE
} from '../constants';

const getReturnAndRefundPolicyDataInitialState = { version: '', url: '' };

const initialState = {
  getReturnAndRefundPolicyDataRequest: false,
  getReturnAndRefundPolicyData: getReturnAndRefundPolicyDataInitialState,
  getReturnAndRefundPolicyDataStatus: false
};

interface stateType {
  getReturnAndRefundPolicyDataRequest: boolean;
  getReturnAndRefundPolicyData: { version: string; url: string };
  getReturnAndRefundPolicyDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: { version: string; url: string };
  };
}

export const getReturnAndRefundPolicyReducer = (
  state = initialState,
  action: actionType
): stateType => {
  switch (action.type) {
  case FETCH_RETURN_AND_REFUND_POLICY_REQUEST:
    return {
      ...state,
      getReturnAndRefundPolicyDataRequest: true,
      getReturnAndRefundPolicyData: getReturnAndRefundPolicyDataInitialState,
      getReturnAndRefundPolicyDataStatus: false
    };
  case FETCH_RETURN_AND_REFUND_POLICY_SUCCESS:
    return {
      ...state,
      getReturnAndRefundPolicyDataRequest: false,
      getReturnAndRefundPolicyData: action.payload.data,
      getReturnAndRefundPolicyDataStatus: true
    };
  case FETCH_RETURN_AND_REFUND_POLICY_FAILURE:
    return {
      ...state,
      getReturnAndRefundPolicyDataRequest: false,
      getReturnAndRefundPolicyData: getReturnAndRefundPolicyDataInitialState,
      getReturnAndRefundPolicyDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
