import {
  FETCH_EDIT_NUMBER,
  FETCH_OTP_FAILURE,
  FETCH_OTP_REQUEST,
  FETCH_OTP_SUCCESS,
  CHECK_OTP_FAILURE,
  CHECK_OTP_REQUEST,
  CHECK_OTP_SUCCESS,
  CHECK_OTP_RESET,
  FETCH_OTP_RESET,
  CHECK_OTP_NEW_CUSTOMER,
  SIGNUP_TOAST,
  APP_LINK_VERIFIED
} from 'src/redux/constants';

// Auth Reducer
const initialStateAuth = {
  inputData: '',
  res: {},
  error: {},
  isError: false,
  otpRequest: false,
  otpFetchStatus: false
};
interface response {
  isCustomer?: number;
  isSupplier?: number;
  isInternalUser?: number;
}
interface stateTypeAuth {
  inputData: string;
  res: response;
  error: { message?: string; code?: number };
  isError: boolean;
  otpRequest: boolean;
  otpFetchStatus: boolean;
}

interface actionTypeAuth {
  type: string;
  payload: {
    inputData: string;
    res: response;
    error: { message: string; code: number };
    showToast?: boolean;
  };
}

export const authReducer = (state = initialStateAuth, action: actionTypeAuth): stateTypeAuth => {
  switch (action.type) {
  case FETCH_OTP_REQUEST:
    return {
      ...state,
      otpRequest: true
    };

  case FETCH_OTP_SUCCESS:
    return {
      ...state,
      inputData: action.payload.inputData,
      res: action.payload.res,
      isError: false,
      otpRequest: false,
      otpFetchStatus: true
    };
  case FETCH_OTP_FAILURE:
    return {
      ...state,
      error: action.payload.error,
      isError: true,
      otpRequest: false,
      otpFetchStatus: false
    };
  case FETCH_OTP_RESET:
    return {
      ...state,
      inputData: '',
      res: {},
      error: {},
      isError: false,
      otpRequest: false,
      otpFetchStatus: false
    };
  case FETCH_EDIT_NUMBER:
    return {
      ...state,
      inputData: action.payload.inputData,
      otpFetchStatus: false
    };
  default:
    return {
      ...state
    };
  }
};

const initialStateLogin = {
  loggedIn: false,
  token: '',
  sendOTP: false,
  OTPPass: false,
  loggedInNewCustomer: false,
  loggedInError: '',
  readingOtpStatus: false
};

interface stateTypeLogin {
  loggedIn: boolean;
  token: string;
  sendOTP: boolean;
  OTPPass: boolean;
  redirectUrl?: string;
  loggedInNewCustomer: boolean;
  loggedInError: string;
  readingOtpStatus: boolean;
  signUpToast?: boolean;
}

interface actionTypeLogin {
  type: string;
  payload: {
    inputData: string;
    token: string;
    error: string;
    showToast?: boolean;
    redirectUrl?: string;
  };
}

export const loginReducer = (
  state = initialStateLogin,
  action: actionTypeLogin
): stateTypeLogin => {
  switch (action.type) {
  case CHECK_OTP_REQUEST:
    return {
      ...state,
      sendOTP: true,
      readingOtpStatus: true
    };

  case CHECK_OTP_SUCCESS:
    return {
      ...state,
      loggedIn: true,
      token: action.payload.token,
      sendOTP: false,
      OTPPass: true,
      loggedInError: '',
      readingOtpStatus: false
      // signUpToast: true,
    };
  case CHECK_OTP_FAILURE:
    return {
      ...state,
      token: '',
      sendOTP: false,
      OTPPass: false,
      loggedInError: action.payload.error,
      readingOtpStatus: false
    };
  case CHECK_OTP_RESET:
    return {
      ...state,
      loggedIn: false,
      token: '',
      sendOTP: false,
      OTPPass: false,
      loggedInNewCustomer: false,
      loggedInError: '',
      readingOtpStatus: false,
      redirectUrl: action.payload.redirectUrl
    };
  case CHECK_OTP_NEW_CUSTOMER:
    return {
      ...state,
      loggedIn: false,
      token: '',
      sendOTP: false,
      OTPPass: true,
      loggedInNewCustomer: true,
      loggedInError: '',
      readingOtpStatus: false
    };

  case APP_LINK_VERIFIED:
    return {
      ...state,
      loggedIn: true,
      token: action.payload.token,
    };

  case SIGNUP_TOAST:
    return {
      ...state,
      signUpToast: action.payload.showToast
    };

  default:
    return {
      ...state
    };
  }
};
