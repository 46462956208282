export const Navigate = {
  login: '/login',
  terms: '/terms',
  otp: '/otp',
  notregistered: '/not-registered',
  homescreen: '/customer/home',
  requestcreate: '/customer/create-request/create',
  appLink: '/customer/app-link',
  feedBack: '/customer/feedback/',
  alradyFeedBack: '/customer/alradyFeedBack',
  editspecifications: '/customer/editspecifications',
  topselling: '/topSelling',
  quotes: '/customer/quotes',
  quotedetails: '/customer/quote-detail',
  profile: '/customer/account/profile',
  orders: '/customer/orders',
  intransitdetails: '/customer/intransit-detail',
  productiondetails: '/customer/production-detail',
  account: '/customer/account',
  payments: '/customer/payments',
  search: '/customer/search',
  deliveredDetails: '/customer/delivered-detail',
  createOrder: '/customer/create-order/select-quotes',
  createOrderConfirmDetails: '/customer/create-order/ConfirmDetails',
  liveQualityDetails: '/customer/liveQualityDetailsView',
  liveQualityCreateOrderConfirmDetails: '/customer/create-order/Live-Quality/ConfirmDetails',
  topSellingCreateOrderConfirmDetails: '/customer/create-order/ConfirmDetails',
  createOrederPaymentDetails: '/customer/create-order/paymentDetails',
  createCustomer: '/createCustomer',

  PaymentInternational:'/payment-international',

  // Parameter Constants
  parameters: {
    fabric: '/fabrics/',
    order: '/orders/',
    raiseIssue: '/raise-issue/',
  },
};

// Extra Parameters
export const parameters = {
  fabId: `${Navigate.parameters.fabric}:fabId`,
  feedbackId: ':feedbackOrderId',
  orderId: `${Navigate.parameters.order}:orderId`,
};

export const RouteName = {
  login: Navigate.login,
  notregistered: Navigate.notregistered,
  homescreen: Navigate.homescreen,
  appLink: Navigate.appLink,
  quotes: Navigate.quotes,
  quotedetails: Navigate.quotedetails + parameters.fabId,
  profile: Navigate.profile,
  orders: Navigate.orders,
  intransitdetails: Navigate.intransitdetails + parameters.fabId + parameters.orderId,
  productiondetails: Navigate.productiondetails + parameters.fabId + parameters.orderId,
  account: Navigate.account,
  payments: Navigate.payments,
  search: Navigate.search,
  requestcreate: Navigate.requestcreate,
  feedBack: Navigate.feedBack + parameters.feedbackId,
  alradyFeedBack: Navigate.alradyFeedBack,
  editspecifications: Navigate.editspecifications,
  deliveredDetails: Navigate.deliveredDetails + parameters.fabId + parameters.orderId,
  raiseIssue:
    Navigate.intransitdetails +
    parameters.fabId +
    parameters.orderId +
    Navigate.parameters.raiseIssue,
  createOrder: Navigate.createOrder + parameters.fabId,
  CreateOrderConfirmDetails: Navigate.createOrderConfirmDetails + parameters.fabId,
  LiveQualityConfirmDetails: Navigate.liveQualityDetails + parameters.fabId,
  liveQualityCreateOrderConfirmDetails: Navigate.liveQualityCreateOrderConfirmDetails,
  topSellingreateOrderConfirmDetails: Navigate.topSellingCreateOrderConfirmDetails,
  CreateOrderPaymentDetails: Navigate.createOrederPaymentDetails + parameters.fabId,
  createCustomer: Navigate.createCustomer,
  otp: Navigate.otp,
  terms: Navigate.terms,
  topSelling: Navigate.topselling,

  PaymentInternational:Navigate.PaymentInternational,
};
