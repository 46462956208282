// Libraries
import axios from 'axios';
import storage from 'redux-persist/es/storage';
// Constants
import {
  FETCH_LIVE_RATES_REQUEST,
  FETCH_LIVE_RATES_SUCCESS,
  FETCH_LIVE_RATES_FAILURE,
  FETCH_LIVE_RATES_RESET,
} from '../constants';

export const fetchLiveRatesData = (
  pageNumber: number,
) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchLiveRatesRequest());
    const baseUrl = `${process.env.REACT_APP_QUALITY_API_URL}/live-rate/fabric-quotes`;
    const token = await storage.getItem('Token');

    axios
      .get(baseUrl, {
        params: {
          limit: 8,
          pageNumber,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(fetchLiveRatesSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(fetchLiveRatesFailure(error));
      });
  };
};

export const fetchLiveRatesRequest = (): Object => {
  return {
    type: FETCH_LIVE_RATES_REQUEST,
  };
};

export const fetchLiveRatesSuccess = (
  liveRatesData: Object,
): object => {
  return {
    type: FETCH_LIVE_RATES_SUCCESS,
    payload: {
      data: liveRatesData,
    },
  };
};

export const fetchLiveRatesFailure = (error: string): Object => {
  return {
    type: FETCH_LIVE_RATES_FAILURE,
    payload: {
      error,
    },
  };
};

export const fetchLiveRatesReset = (): Object => {
  return {
    type: FETCH_LIVE_RATES_RESET,
  };
};
