// Libraries
import axios from 'axios';
import storage from 'redux-persist/es/storage';

// Constants
import {
  FETCH_ORDER_TNA_REQUEST,
  FETCH_ORDER_TNA_SUCCESS,
  FETCH_ORDER_TNA_FAILURE,
  FETCH_ORDER_TNA_RESET
} from '../constants';

export const fetchOrderTna = (fabricId: number , orderId: number ) => {
  return async function (dispatch: Function): Promise<void> {
    dispatch(fetchOrderTnaRequest());
    const baseUrl = `${process.env.REACT_APP_API_URL}/apifabric/tna/fabrics/${fabricId}/?orderId=${orderId}`;
    const token = await storage.getItem('Token');

    axios
      .get(baseUrl, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch(fetchOrderTnaSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(fetchOrderTnaFailure(error));
      });
  };
};

export const fetchOrderTnaRequest = (): Object => {
  return {
    type: FETCH_ORDER_TNA_REQUEST
  };
};

export const fetchOrderTnaReset = (): Object => {
  return {
    type: FETCH_ORDER_TNA_RESET
  };
};

export const fetchOrderTnaSuccess = (data: object): object => {
  return {
    type: FETCH_ORDER_TNA_SUCCESS,
    payload: {
      data: data
    }
  };
};

export const fetchOrderTnaFailure = (error: string): Object => {
  return {
    type: FETCH_ORDER_TNA_FAILURE,
    payload: {
      error
    }
  };
};
