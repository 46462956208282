import { FETCH_ORDER_REQUEST, FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE } from '../constants';

const initialState = {
  orderDataRequest: false,
  orderData: {},
  orderDataStatus: false
};

interface stateType {
  orderDataRequest: boolean;
  orderData: Object;
  orderDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const orderDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_ORDER_REQUEST:
    return {
      ...state,
      orderDataRequest: true,
      orderData: {},
      orderDataStatus: false
    };

  case FETCH_ORDER_SUCCESS:
    return {
      ...state,
      orderDataRequest: false,
      orderData: action.payload.data,
      orderDataStatus: true
    };
  case FETCH_ORDER_FAILURE:
    return {
      ...state,
      orderDataRequest: false,
      orderData: {},
      orderDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
