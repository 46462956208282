import { FETCH_PAYMENT_DETAILS_FAILURE, FETCH_PAYMENT_DETAILS_REQUEST, FETCH_PAYMENT_DETAILS_SUCCESS } from '../constants';

const initialState = {
  getPaymentDetailsDataRequest: false,
  getPaymentDetailsData: {},
  getPaymentDetailsDataStatus: false
};

interface stateType {
  getPaymentDetailsDataRequest: boolean;
  getPaymentDetailsData: Object;
  getPaymentDetailsDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const getPaymentDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_PAYMENT_DETAILS_REQUEST:
    return {
      ...state,
      getPaymentDetailsDataRequest: true,
      getPaymentDetailsData: {},
      getPaymentDetailsDataStatus: false
    };
  case FETCH_PAYMENT_DETAILS_SUCCESS:
    return {
      ...state,
      getPaymentDetailsDataRequest: false,
      getPaymentDetailsData: action.payload.data,
      getPaymentDetailsDataStatus: true,
    };
  case FETCH_PAYMENT_DETAILS_FAILURE:
    return {
      ...state,
      getPaymentDetailsDataRequest: false,
      getPaymentDetailsData: {},
      getPaymentDetailsDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
