import {
  CHECK_WHATSAPP_OTP_FAILURE,
  CHECK_WHATSAPP_OTP_REQUEST,
  CHECK_WHATSAPP_OTP_RESET,
  CHECK_WHATSAPP_OTP_SUCCESS,
  FETCH_WHATSAPP_OTP_FAILURE,
  FETCH_WHATSAPP_OTP_REQUEST,
  FETCH_WHATSAPP_OTP_RESET,
  FETCH_WHATSAPP_OTP_SUCCESS,
} from '../constants';

const initialState = {
  whatsAppUpdated: false,
  sendWhatsAppOtp: false,
  whatsAppOTPSuccess: false,
  whatsAppUpdatedError: '',
  readingWhatsAppOtp: false,
};

interface actionTypeLogin {
  type: string;
  payload: {
    inputData: string;
    error: string;
    showToast?: boolean;
  };
}
interface stateTypeLogin {
  whatsAppUpdated: boolean;
  sendWhatsAppOtp: boolean;
  whatsAppOTPSuccess: boolean;
  whatsAppUpdatedError: string;
  readingWhatsAppOtp: boolean;
}

export const whatsAppUpdateNumberReducer = (
  state = initialState,
  action: actionTypeLogin,
): stateTypeLogin => {
  switch (action.type) {
    case CHECK_WHATSAPP_OTP_REQUEST:
      return {
        ...state,
        sendWhatsAppOtp: true,
        readingWhatsAppOtp: true,
      };

    case CHECK_WHATSAPP_OTP_SUCCESS:
      return {
        ...state,
        whatsAppUpdated: true,
        sendWhatsAppOtp: false,
        whatsAppOTPSuccess: true,
        whatsAppUpdatedError: '',
        readingWhatsAppOtp: false,
        // signUpToast: true,
      };
    case CHECK_WHATSAPP_OTP_FAILURE:
      return {
        ...state,
        sendWhatsAppOtp: false,
        whatsAppOTPSuccess: false,
        whatsAppUpdatedError: action.payload.error,
        readingWhatsAppOtp: false,
      };
    case CHECK_WHATSAPP_OTP_RESET:
      return {
        ...state,
        whatsAppUpdated: false,
        sendWhatsAppOtp: false,
        whatsAppOTPSuccess: false,
        whatsAppUpdatedError: '',
        readingWhatsAppOtp: false,
      };
    default:
      return {
        ...state,
      };
  }
};
interface stateTypeAuth {
  resForWhatsApp: {};
  errorForWhatsApp: { message?: string; code?: number };
  isErrorForWhatsApp: boolean;
  otpRequestForWhatsApp: boolean;
  otpFetchStatusForWhatsApp: boolean;
}

interface actionTypeAuth {
  type: string;
  payload: {
    resForWhatsApp: {message:string};
    errorForWhatsApp: { message: string; code: number };
    showToast?: boolean;
  };
}
const initialStateForWhatsApp = {
  resForWhatsApp: {},
  errorForWhatsApp: {},
  isErrorForWhatsApp: false,
  otpRequestForWhatsApp: false,
  otpFetchStatusForWhatsApp: false,
};
export const requestOtpForWhatsAppReducer = (
  state = initialStateForWhatsApp,
  action: actionTypeAuth,
): stateTypeAuth => {
  switch (action.type) {
    case FETCH_WHATSAPP_OTP_REQUEST:
      return {
        ...state,
        otpRequestForWhatsApp: true,
      };

    case FETCH_WHATSAPP_OTP_SUCCESS:
      return {
        ...state,
        resForWhatsApp: action.payload,
        isErrorForWhatsApp: false,
        otpRequestForWhatsApp: false,
        otpFetchStatusForWhatsApp: true,
      };
    case FETCH_WHATSAPP_OTP_FAILURE:
      return {
        ...state,
        errorForWhatsApp: action.payload.errorForWhatsApp,
        isErrorForWhatsApp: true,
        otpRequestForWhatsApp: false,
        otpFetchStatusForWhatsApp: false,
      };
    case FETCH_WHATSAPP_OTP_RESET:
      return {
        ...state,
        resForWhatsApp: {},
        errorForWhatsApp: {},
        isErrorForWhatsApp: false,
        otpRequestForWhatsApp: false,
        otpFetchStatusForWhatsApp: false,
      };

    default:
      return {
        ...state,
      };
  }
};
