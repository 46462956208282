import {
  CUSTOMER_FEEDBACK_REQUEST,
  CUSTOMER_FEEDBACK_SUCCESS,
  CUSTOMER_FEEDBACK_FAILURE,
  CUSTOMER_FEEDBACK_RESET,
} from '../constants';

const initialState = {
  feedbackRequest: false,
  feedbackResponseData: {},
  feedbackResponseDataStatus: false,
  error: '',
};

interface stateType {
  feedbackRequest: boolean;
  feedbackResponseData: Object;
  feedbackResponseDataStatus: boolean;
  error: String;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
    error: string;
  };
}

export const customerFeedbackReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case CUSTOMER_FEEDBACK_REQUEST:
    return {
      ...state,
      feedbackRequest: true,
      feedbackResponseData: {},
      feedbackResponseDataStatus: false,
    };

  case CUSTOMER_FEEDBACK_SUCCESS:
    return {
      ...state,
      feedbackRequest: false,
      feedbackResponseData: action.payload.data,
      feedbackResponseDataStatus: true,
    };
  case CUSTOMER_FEEDBACK_FAILURE:
    return {
      ...state,
      feedbackRequest: false,
      error: action.payload.error,
      feedbackResponseDataStatus: false,
    };
  case CUSTOMER_FEEDBACK_RESET:
    return initialState;
  default:
    return {
      ...state,
    };
  }
};
