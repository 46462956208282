import { UPDATE_CREDIT_PERIOD_REQUEST, UPDATE_CREDIT_PERIOD_SUCCESS, UPDATE_CREDIT_PERIOD_FAILURE } from '../constants';

const initialState = {
  creditPeriodLoadingStatus: false,
  creditPeriodResponse: { success: false },
  creditPeriodStatus: false
};
interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const updateCreditPeriodReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case UPDATE_CREDIT_PERIOD_REQUEST:
    return {
      ...state,
      creditPeriodLoadingStatus: false,
      creditPeriodResponse: {},
      creditPeriodStatus: false
    };

  case UPDATE_CREDIT_PERIOD_SUCCESS:
    return {
      ...state,
      success: true,
      creditPeriodLoadingStatus: false,
      creditPeriodResponse: action.payload.data,
      creditPeriodStatus: false
    };
  case UPDATE_CREDIT_PERIOD_FAILURE:
    return {
      creditPeriodLoadingStatus: false,
      creditPeriodResponse: {},
      creditPeriodStatus: false,
      error: action?.payload
    };

  default:
    return {};
  }
};
