import {
  FETCH_ORDER_TNA_REQUEST,
  FETCH_ORDER_TNA_SUCCESS,
  FETCH_ORDER_TNA_FAILURE,
  FETCH_ORDER_TNA_RESET
} from '../constants';

const initialState = {
  orderTnaData: {},
  orderTnaRequest: false,
  orderTnaFetchStatus: false,
  orderTnaFetchError: false,
  error: ''
};

interface actionTypes {
  type: string;
  payload: {
    data: object;
    error: string;
  };
}

interface stateTypes {
  orderTnaData: object;
  orderTnaRequest: boolean;
  orderTnaFetchStatus: boolean;
  orderTnaFetchError: boolean;
  error: string;
}

export const orderTnaReducer = (state = initialState, action: actionTypes): stateTypes => {
  switch (action.type) {
  case FETCH_ORDER_TNA_REQUEST:
    return {
      ...state,
      orderTnaRequest: true
    };

  case FETCH_ORDER_TNA_RESET:
    return {
      ...state,
      orderTnaData: {},
      orderTnaRequest: false,
      orderTnaFetchStatus: false,
      orderTnaFetchError: false,
      error: ''
    };

  case FETCH_ORDER_TNA_SUCCESS:
    return {
      ...state,
      orderTnaRequest: false,
      orderTnaFetchStatus: true,
      orderTnaData: action.payload.data
    };

  case FETCH_ORDER_TNA_FAILURE:
    return {
      ...state,
      orderTnaFetchError: true,
      orderTnaFetchStatus: false,
      orderTnaRequest: false,
      error: action.payload.error
    };

  default:
    return { ...state };
  }
};
