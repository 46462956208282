import { ORDER_TAB, QUOTE_TAB } from 'src/constants';
import { SET_ORDERS_TAB, SET_ORDERS_TAB_SCROLL_OFFSET, SET_QUOTES_TAB, SET_QUOTES_TAB_SCROLL_OFFSET } from '../constants';

const initialState = {
  quotes_page_tab: QUOTE_TAB.RECEIVED,
  order_page_tab: '10',
};

interface stateType {
  quotes_page_tab: string;
  order_page_tab: string;
}

interface actionType {
  type: string;
  payload: {
    tabId: string;
  };
}

export const setTabReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case SET_QUOTES_TAB:
    return {
      ...state,
      quotes_page_tab: action.payload.tabId,
    };
  case SET_ORDERS_TAB:
    return {
      ...state,
      order_page_tab: action.payload.tabId,
    };
  default:
    return {
      ...state,
    };
  }
};

const scrollOffsetInitialState = {
  quotes_page_tab: {
    [QUOTE_TAB.ALL_OPEN]: 0,
    [QUOTE_TAB.RECEIVED]: 0,
    [QUOTE_TAB.NOT_RECEIVED]: 0,
    [QUOTE_TAB.CLOSED]: 0,
  },
  order_page_tab: {
    [ORDER_TAB.PRODUCTION]: 0,
    [ORDER_TAB.IN_TRANSIT]: 0,
    [ORDER_TAB.DELIVERED]: 0,
  },
};

interface scrollOffsetType {
  type: string;
  payload: {
    tabName: string;
    scrollOffset: number;
  };
}

export const setTabOffsetReducer = (
  state = scrollOffsetInitialState,
  action: scrollOffsetType,
): Object => {
  switch (action.type) {
  case SET_ORDERS_TAB_SCROLL_OFFSET:
    return {
      ...state,
      order_page_tab: {
        ...state.order_page_tab,
        [action.payload.tabName]: action.payload.scrollOffset,
      },
    };
  case SET_QUOTES_TAB_SCROLL_OFFSET:
    return {
      ...state,
      quotes_page_tab: {
        ...state.quotes_page_tab,
        [action.payload.tabName]: action.payload.scrollOffset,
      },
    }
  default:
    return state;
  }
};
