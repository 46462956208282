import {
  ADD_SAMPLE_APPROVAL_REQUEST,
  ADD_SAMPLE_APPROVAL_SUCCESS,
  ADD_SAMPLE_APPROVAL_FAILURE,
} from '../constants';

const initialState = {
  addSampleApprovalRequest: false,
  addSampleApprovalData: {},
  addSampleApprovalStatus: false,
};

interface stateType {
  addSampleApprovalRequest: boolean;
  addSampleApprovalData: Object;
  addSampleApprovalStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const addSampleApprovalReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
    case ADD_SAMPLE_APPROVAL_REQUEST:
      return {
        ...state,
        addSampleApprovalRequest: true,
        addSampleApprovalData: {},
        addSampleApprovalStatus: false,
      };
    case ADD_SAMPLE_APPROVAL_SUCCESS:
      return {
        ...state,
        addSampleApprovalRequest: false,
        addSampleApprovalData: action.payload.data,
        addSampleApprovalStatus: true,
      };
    case ADD_SAMPLE_APPROVAL_FAILURE:
      return {
        ...state,
        addSampleApprovalRequest: false,
        addSampleApprovalData: {},
        addSampleApprovalStatus: false,
      };
    default:
      return state;
  }
};
