import { CREATE_REQUEST_REQUEST, CREATE_REQUEST_SUCCESS, CREATE_REQUEST_FAILURE } from '../constants';

const initialState = {
  isLoading: false,
};

interface stateType {
  success?: boolean;
  isLoading?: boolean;
  error?: {
    data?: {
      message?: string
  }
  };
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createRequestReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case CREATE_REQUEST_REQUEST:
    return {
      isLoading: true
    };

  case CREATE_REQUEST_SUCCESS:
    return {
      success: true,
      isLoading: false,
    };
  case CREATE_REQUEST_FAILURE:
    return {
      error: action?.payload,
      isLoading:false
    };
    
  default:
    return {};
  }
};
