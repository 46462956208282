import { searchResultCardType } from 'src/interfaces/reactPageProps';
import {
  FETCH_SEARCH_RESULT_FAILURE,
  FETCH_SEARCH_RESULT_SUCCESS,
  FETCH_SEARCH_RESULT_REQUEST,
} from '../constants';

const initialState = {
  searchResultDataRequest: false,
  searchResultData: [],
  searchResultDataStatus: false,
};

interface stateType {
    searchResultDataRequest: boolean;
    searchResultData: Array<searchResultCardType>;
    searchResultDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Array<searchResultCardType>;
  };
}

export const searchOrderReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_SEARCH_RESULT_REQUEST:
    return {
      ...state,
      searchResultDataRequest: true,
      searchResultData: [],
      searchResultDataStatus: false,
    };

  case FETCH_SEARCH_RESULT_SUCCESS:
    return {
      ...state,
      searchResultDataRequest: false,
      searchResultData: action.payload.data,
      searchResultDataStatus: true,
    };
  case FETCH_SEARCH_RESULT_FAILURE:
    return {
      ...state,
      searchResultDataRequest: false,
      searchResultData: [],
      searchResultDataStatus: false,
    };
  default:
    return state;
  }
};
