import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import { rootReducer } from 'src/redux/reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['msmeDetails'] // to be check why for this reducer
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store<any, AnyAction> = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export default store;
