import { UPDATE_ADDRESS_RESET } from './../constants';
import { UPDATE_ADDRESS_FAILURE, UPDATE_ADDRESS_REQUEST, UPDATE_ADDRESS_SUCCESS } from '../constants';

const initialState = {
  addressDataStatus:false,
};

interface stateType {
  updatedAddressData: boolean;
  addressData: Object;
  addressDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateCustomerAddressReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case UPDATE_ADDRESS_REQUEST:
    return {
      ...state,
      addressDataStatus:false,
    };

  case UPDATE_ADDRESS_SUCCESS:
    return {
      ...state,
      ...action.payload,
      addressDataStatus:true,
      success: true
    };
  case UPDATE_ADDRESS_FAILURE:
    return {
      ...state,
      addressDataStatus:false,
      error: action?.payload
    };
  case UPDATE_ADDRESS_RESET:
    return {
      ...state,
      addressDataStatus:false,
    };
    
  default:
    return state
  }
};
