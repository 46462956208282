import {
  FETCH_ADDITIONAL_QUOTE_DETAILS_FAILURE,
  FETCH_ADDITIONAL_QUOTE_DETAILS_REQUEST,
  FETCH_ADDITIONAL_QUOTE_DETAILS_SUCCESS
} from '../constants';

const initialState = {
  quoteAdditionalDetailRequest: false,
  quoteAdditionalDetailData: {},
  quoteAdditionalDetailStatus: false
};

interface stateType {
  quoteAdditionalDetailRequest: boolean;
  quoteAdditionalDetailData: Object;
  quoteAdditionalDetailStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: string;
  };
}

export const quoteAdditionalDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case FETCH_ADDITIONAL_QUOTE_DETAILS_REQUEST:
    return {
      ...state,
      quoteAdditionalDetailRequest: true,
      quoteAdditionalDetailData: {},
      quoteAdditionalDetailStatus: false
    };

  case FETCH_ADDITIONAL_QUOTE_DETAILS_SUCCESS:
    return {
      ...state,
      quoteAdditionalDetailRequest: false,
      quoteAdditionalDetailData: action.payload.data,
      quoteAdditionalDetailStatus: true
    };
  case FETCH_ADDITIONAL_QUOTE_DETAILS_FAILURE:
    return {
      ...state,
      quoteAdditionalDetailRequest: false,
      quoteAdditionalDetailData: {},
      quoteAdditionalDetailStatus: false
    };
  default:
    return {
      ...state
    };
  }
};
