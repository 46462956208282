import {
  FETCH_INTRANSIT_ORDER_REQUEST,
  FETCH_INTRANSIT_ORDER_SUCCESS,
  FETCH_INTRANSIT_ORDER_FAILURE,
  FETCH_DELIVERED_ORDER_SUCCESS,
  FETCH_FABRIC_ISSUES_REQUEST,
  FETCH_FABRIC_ISSUES_SUCCESS,
  FETCH_FABRIC_ISSUES_FAILURE
} from '../constants';

const initialState = {
  intransitData: {},
  intransitDataRequest: false,
  intransitDataFetchStatus: false,
  intransitDataFetchError: false,
  deliveredData: {},
  fabricIssues: [],
  fabricIssuesLoading: false,
  error: ''
};

// api data types define
type intransitDataTypes = {
  coverPic: string | null;
  destination?: string;
  dispatchDate: string;
  fabricId?: number;
  generatedFabricId?: string;
  managerName?: string;
  name?: string;
  offsetTs?: string;
  orderFabricId?: number;
  orderId?: number;
  orderStatus?: number;
  quantity: string;
  requirementType: number;
  sliceState?: number;
  sourceCity?: string;
  tentativeDeliveryDate?: string;
  unit?: string;
};

interface actionTypes {
  type: string;
  payload: {
    data: { 
      records?: intransitDataTypes[],
      issues: {id:number, description: string, productionType: number}[],
     };
    error: string;
  };
}

interface stateTypes {
  intransitData: { records?: intransitDataTypes[] };
  deliveredData: { records?: intransitDataTypes[] };
  intransitDataRequest: boolean;
  intransitDataFetchStatus: boolean;
  intransitDataFetchError: boolean;
  fabricIssues?: {id:number, description: string, productionType: number}[];
  fabricIssuesLoading: boolean;
  error: string;
}

export const intransitOrderReducer = (state = initialState, action: actionTypes): stateTypes => {
  switch (action.type) {
  case FETCH_INTRANSIT_ORDER_REQUEST:
    return {
      ...state,
      intransitDataRequest: true
    };

  case FETCH_INTRANSIT_ORDER_SUCCESS:
    return {
      ...state,
      intransitDataRequest: false,
      intransitDataFetchStatus: true,
      intransitData: action.payload.data
    };

  case FETCH_DELIVERED_ORDER_SUCCESS:
    return {
      ...state, 
      deliveredData: action.payload.data
    }

  case FETCH_INTRANSIT_ORDER_FAILURE:
    return {
      ...state,
      intransitDataFetchError: true,
      intransitDataFetchStatus: false,
      intransitDataRequest: false,
      error: action.payload.error
    };

  case FETCH_FABRIC_ISSUES_REQUEST:
    return {
      ...state,
      fabricIssuesLoading: true,
    }
  case FETCH_FABRIC_ISSUES_SUCCESS:
    return {
      ...state,
      fabricIssuesLoading: false,
      fabricIssues: action.payload.data?.issues,
    }
  case FETCH_FABRIC_ISSUES_FAILURE:
    return {
      ...state,
      fabricIssuesLoading: false,
    }

  default:
    return state;
  }
};
