import {
  FETCH_PREVIOUS_ORDERS_FAILURE,
  FETCH_PREVIOUS_ORDERS_REQUEST,
  FETCH_PREVIOUS_ORDERS_SUCCESS,
} from '../constants';

const initialState = {
  data: [],
  dataRequest: false,
  dataRequestSuccess: false,
};

interface actionTypes {
  type: string;
  payload: Array<{}>;
}

interface stateTypes {
  data: Array<{}>;
  dataRequest: boolean;
  dataRequestSuccess: boolean;
}

export const previousOrdersReducer = (state = initialState, action: actionTypes): stateTypes => {
  switch (action.type) {
  case FETCH_PREVIOUS_ORDERS_REQUEST:
    return {
      ...state,
      dataRequest: true,
      dataRequestSuccess: false,
    };

  case FETCH_PREVIOUS_ORDERS_SUCCESS:
    return {
      ...state,
      dataRequest: false,
      dataRequestSuccess: true,
      data: action.payload,
    };

  case FETCH_PREVIOUS_ORDERS_FAILURE:
    return {
      ...state,
      dataRequest: false,
      dataRequestSuccess: false,
    };

  default:
    return { ...state };
  }
};
