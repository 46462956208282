import {
  GET_PROVISIONAL_ORDER_REQUEST,
  GET_PROVISIONAL_ORDER_SUCCESS,
  GET_PROVISIONAL_ORDER_FAILURE,
  FETCH_ORDER_VALIDATE_REQUEST,
  FETCH_ORDER_VALIDATE_SUCCESS,
  FETCH_ORDER_VALIDATE_FAILURE,
  FETCH_ORDER_VALIDATE_RESET
} from '../constants';

const initialState = {
  orderConfigDataRequest: false,
  orderConfigData: {},
  orderConfigDataStatus: false
};

interface stateType {
  orderConfigDataRequest: boolean;
  orderConfigData: Object;
  orderConfigDataStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

export const orderConfigReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case GET_PROVISIONAL_ORDER_REQUEST:
    return {
      ...state,
      orderConfigDataRequest: true,
      orderConfigData: {},
      orderConfigDataStatus: false
    };
  case GET_PROVISIONAL_ORDER_SUCCESS:
    return {
      ...state,
      orderConfigDataRequest: false,
      orderConfigData: action.payload.data,
      orderConfigDataStatus: true
    };
  case GET_PROVISIONAL_ORDER_FAILURE:
    return {
      ...state,
      orderConfigDataRequest: false,
      orderConfigData: {},
      orderConfigDataStatus: false
    };
  default:
    return {
      ...state
    };
  }
};

// Order Validate
const initialStateOrderValidate = {
  orderValidateRequest: false,
  isOrderValid : false,
  isOrderValidError: false,
  errorMessage : ''
};

interface stateTypeOrderValidate {
  orderValidateRequest: boolean;
  isOrderValid: boolean;
  isOrderValidError: boolean;
  errorMessage: string
}

interface actionTypeOrderValidate {
  type: string;
  payload: {
    errorMessage: string
  }
}

export const orderValidateReducer = (
  state = initialStateOrderValidate,
  action: actionTypeOrderValidate
): stateTypeOrderValidate => {
  switch (action.type) {
  case FETCH_ORDER_VALIDATE_REQUEST:
    return {
      ...state,
      orderValidateRequest: true,
      isOrderValid: false,
      isOrderValidError: false,
      errorMessage: ''
    };
  case FETCH_ORDER_VALIDATE_SUCCESS:
    return {
      ...state,
      orderValidateRequest: false,
      isOrderValid: true,
      isOrderValidError: false,
      errorMessage: ''
    };
  case FETCH_ORDER_VALIDATE_FAILURE:
    return {
      ...state,
      orderValidateRequest: false,
      isOrderValid: false,
      isOrderValidError: true,
      errorMessage: action.payload.errorMessage
    };
  case FETCH_ORDER_VALIDATE_RESET:
    return{
      ...state,
      orderValidateRequest: false,
      isOrderValid: false,
      isOrderValidError: false,
      errorMessage: ''
    }
  default:
    return {
      ...state
    };
  }
};
