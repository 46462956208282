import {
  UPDATE_REFERENCE_NUMBER_FAILURE,
  UPDATE_REFERENCE_NUMBER_REQUEST,
  UPDATE_REFERENCE_NUMBER_SUCCESS
} from '../constants';

const initialState = {
  success: false
};

interface stateType {
  updateReferenceNumberDataRquest: boolean;
  updateReferenceNumberData: Object;
  updateReferenceNumberStatus: boolean;
}

interface actionType {
  type: string;
  payload: {
    data: Object;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateReferenceNumberReducer = (state = initialState, action: actionType): object => {
  switch (action.type) {
  case UPDATE_REFERENCE_NUMBER_REQUEST:
    return {};

  case UPDATE_REFERENCE_NUMBER_SUCCESS:
    return {
      success: true
    };
  case UPDATE_REFERENCE_NUMBER_FAILURE:
    return {
      error: action?.payload
    };

  default:
    return {};
  }
};
