import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'src/redux/store';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './styles/index.scss';
import 'antd/dist/antd.min.css';
//@ts-ignore
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
render(
  <StrictMode>
    <Provider store={store}>
      {/* @ts-ignore */}
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);