import {
  SHOW_MSME_FLOW,
  UPDATE_MSME_DETAILS_FAILURE,
  UPDATE_MSME_DETAILS_SUCCESS,
  UPDATE_MSME_DETAILS_RESET,
  UPDATE_MSME_DETAILS_REQUEST,
} from '../constants';

const initialState = {
  msmeDetailsRequest: false,
  msmedetailsUpdated: false,
  shwoMsmeFlow: false,
  msmeError: {},
};

interface stateType {
  msmeDetailsRequest: boolean;
  msmedetailsUpdated: boolean;
  shwoMsmeFlow: boolean;
  msmeError: {
    message?: string;
  };
}

interface actionType {
  type: string;
  payload: {
    data: Object;
    isMsmeFlowVisible: boolean;
    error: object;
  };
}

export const msmeDetailsReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
  case UPDATE_MSME_DETAILS_REQUEST:
    return {
      ...state,
      msmeDetailsRequest: true,
      msmedetailsUpdated: false,
    };
  case UPDATE_MSME_DETAILS_SUCCESS:
    return {
      ...state,
      msmeDetailsRequest: false,
      msmedetailsUpdated: true,
    };
  case UPDATE_MSME_DETAILS_FAILURE:
    return {
      ...state,
      msmeDetailsRequest: false,
      msmeError: action.payload.error,
    };
  case UPDATE_MSME_DETAILS_RESET:
    return initialState;

  case SHOW_MSME_FLOW:
    return { ...state, shwoMsmeFlow: action.payload.isMsmeFlowVisible };

  default:
    return state;
  }
};
